import React from 'react'
import {
	Typography,
	ListItem,
	ListItemText,
	makeStyles,
} from '@material-ui/core'

const TeamItem = ({ team, handleClick }) => {
	const classes = useStyles()
	return (
		<ListItem
			button
			onClick={() =>
				handleClick ? handleClick(team) : null
			}
			className={classes.root}
		>
			<ListItemText
				primary={
					<Typography variant="body2">
						{team?.name}
					</Typography>
				}
			/>
		</ListItem>
	)
}

export default TeamItem

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
}))
