import React from 'react'
import { makeStyles } from '@material-ui/core'
import {
	Lens as CircleFilled,
	LensOutlined as CircleOutlined,
} from '@material-ui/icons'

const CircleRating = ({
	rating = 1,
	maxRating = 5,
}) => {
	let filledRatings = new Array(rating).fill(0)
	let outlinedRatings = new Array(
		maxRating - rating
	).fill(0)

	const classes = useStyles()

	return (
		<div className={classes.root}>
			{filledRatings.map((rating, i) => (
				<CircleFilled
					key={i}
					className={classes.icon}
				/>
			))}
			{outlinedRatings.map((rating, i) => (
				<CircleOutlined
					key={i}
					className={classes.icon}
				/>
			))}
		</div>
	)
}

export default CircleRating

const useStyles = makeStyles((theme) => ({
	root: {
		whiteSpace: 'nowrap',
	},
	icon: {
		marginRight: 5,
		height: 16,
		width: 16,
	},
	rating: {
		flexDirection: 'row',
		paddingLeft: 15,
	},
}))
