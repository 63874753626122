import React, { useState } from 'react'
import api from 'api'
import axios from 'axios'
import {
	CLOUDINARY_CLOUD_NAME,
	CLOUDINARY_API_KEY,
	CLOUDINARY_PRESET,
} from 'lib/constants'

const useCloudinary = () => {
	const cloudinaryUpload = async (file, folder) => {
		const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/auto/upload`

		const { uri, filename } = file
		const formData = new FormData()
		const timestamp = Math.round(Date.now() / 1000)

		formData.append('file', file)
		formData.append('source', filename)
		formData.append('folder', folder)
		formData.append('api_key', CLOUDINARY_API_KEY)
		formData.append(
			'upload_preset',
			CLOUDINARY_PRESET
		)
		formData.append('timestamp', timestamp)
		// Remove JWT token headers from app
		delete axios.defaults.headers.common[
			'Authorization'
		]

		try {
			let resp = await axios.post(
				cloudinaryUrl,
				formData,
				{
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
				}
			)
			return resp
		} catch (e) {
			console.log('Cloudinary Error: ', e)
		} finally {
			console.log('Finally')
		}
	}

	return {
		cloudinaryUpload,
	}
}

export default useCloudinary
