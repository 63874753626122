import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import LazyLoad from 'react-lazyload'
import {
	THUMBNAIL_HEIGHT,
	THUMBNAIL_WIDTH_X4,
} from 'lib/constants'

const ResponsiveImage = ({
	src,
	disableGradient = false,
	borderRadius,
	height = THUMBNAIL_HEIGHT,
	width = THUMBNAIL_WIDTH_X4,
	opacity = 1.0,
}) => {
	const classes = useStyles({
		disableGradient,
		borderRadius,
		height,
		width,
		opacity,
	})

	const [loaded, setLoaded] = useState(false)

	const handleLoad = () => {
		setLoaded(true)
	}

	return (
		<div className={classes.root}>
			{!loaded && (
				<Skeleton
					variant="rect"
					height={height}
					width={width}
					className={classes.skeleton}
				/>
			)}
			<LazyLoad>
				<img
					src={src}
					onLoad={handleLoad}
					disableGradient={disableGradient}
					className={clsx(classes.responsive, {
						[classes.gradient]: !disableGradient,
					})}
				/>
			</LazyLoad>
		</div>
	)
}

export default ResponsiveImage

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	responsive: {
		borderRadius: (props) =>
			props.borderRadius ? 8 : 0,
		width: '100%',
		height: 'auto',
		objectFit: 'cover',
		opacity: (props) =>
			props.opacity ? props.opacity : 1.0,
	},
	gradient: {
		maskImage:
			'linear-gradient(to top, rgba(0,0,0,0.10) 0%,rgba(0,0,0,1.0) 30%)',
	},
	skeleton: {
		borderRadius: 8,
		backgroundColor: theme.palette.background.light,
	},
}))
