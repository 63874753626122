import React from 'react'
import {
	Box,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import CourseMeta from 'containers/courses/CourseMeta'
import WhatYouNeedIcons from 'containers/lessons/WhatYouNeedIcons'
import ProgressBars from 'containers/skills/ProgressBars'
import { useResponsive } from 'hooks'

const WhatToKnow = ({ course }) => {
	const classes = useStyles()
	return (
		<div>
			<Typography
				variant="subtitle1"
				color="secondary"
				className={classes.subheaderText}
			>
				WHAT TO KNOW
			</Typography>
			<Typography
				variant="body2"
				color="primary"
				className={classes.description}
			>
				{course.description}
			</Typography>
		</div>
	)
}

const TopSkills = ({ course }) => {
	const classes = useStyles()
	return (
		<div>
			<Typography
				variant="subtitle1"
				color="secondary"
				className={classes.subheaderText}
			>
				TOP SKILLS
			</Typography>
			<ProgressBars points={course?.points} />
		</div>
	)
}

const CourseDetails = ({ course }) => {
	const classes = useStyles()
	const { isMobile } = useResponsive()
	return (
		<div>
			<Typography
				variant="h2"
				color="textPrimary"
				className={classes.title}
			>
				{course.title}
			</Typography>
			<Typography
				variant="subtitle1"
				color="secondary"
				className={classes.subtitle}
			>
				{course.subtitle}
			</Typography>
			<CourseMeta fontSize={14} course={course} />
			<Box my={10} />
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<WhatToKnow course={course} />
					{isMobile ? (
						<TopSkills course={course} />
					) : (
						<WhatYouNeedIcons
							icons={course.what_you_need}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					{isMobile ? (
						<WhatYouNeedIcons
							icons={course.what_you_need}
						/>
					) : (
						<TopSkills course={course} />
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default CourseDetails

const useStyles = makeStyles((theme) => ({
	root: {},
	subtitle: {
		marginTop: 8,
		fontWeight: 600,
		fontSize: 20,
	},
	description: {
		marginBottom: theme.spacing(4),
	},
}))
