import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
	useResponsive,
	useClubs,
	useAlerts,
} from 'hooks'
import {
	Button,
	Grid,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { StickyButton } from 'components'
import ClubDetails from 'containers/clubs/ClubDetails'
import TeamList from 'containers/teams/TeamList'
import clsx from 'clsx'

const ClubShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params
	const classes = useStyles()
	const { showAlertError } = useAlerts()
	const { isLoading, club, findClub } = useClubs({
		id: id,
	})

	const { isMobile } = useResponsive()

	const handleClick = () => {
		if (!club?.contact_email) {
			showAlertError(
				'Sorry, no contact email available yet.'
			)
			return
		}
		window.open(
			`mailto:${club.contact_email}`,
			'_blank'
		)
	}

	useEffect(() => {
		if (id) {
			findClub(id)
		}
	}, [id])

	return (
		<div className={classes.root}>
			<Container>
				<div className={classes.titleBar}>
					<div className={classes.title}>
						{club?.image_url && (
							<center>
								<img
									src={club.image_url}
									className={classes.logo}
								/>
							</center>
						)}
						<center>
							<Typography variant="h2">
								{club?.name
									? club.name
									: 'Loading...'}
							</Typography>
						</center>
					</div>
				</div>

				{club && (
					<div className={classes.panels}>
						<div className={classes.leftPanel}>
							<div className={classes.header}>
								<Typography variant="h3">
									CLUB DETAILS
								</Typography>
							</div>
							<ClubDetails club={club} />
						</div>
						<div className={classes.rightPanel}>
							<Typography variant="h3">
								TEAMS
							</Typography>
							<TeamList teams={club?.teams} />
						</div>
					</div>
				)}
			</Container>
			<StickyButton>
				<Button
					fullWidth
					onClick={handleClick}
					variant="contained"
					color="primary"
					className={clsx(classes.button, {
						[classes.disabledButton]:
							club?.contact_email ? false : true,
					})}
				>
					Contact Club
				</Button>
			</StickyButton>
		</div>
	)
}

export default ClubShow

const useStyles = makeStyles((theme) => ({
	root: {},
	panels: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 20,
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	leftPanel: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	rightPanel: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginTop: theme.spacing(2),
		},
	},
	titleBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 80,
		[theme.breakpoints.down('sm')]: {
			marginTop: 20,
		},
	},
	title: {
		width: '100%',
	},
	header: {
		marginBottom: 5,
	},
	position: {
		textTransform: 'uppercase',
	},
	container: {
		overflowY: 'scroll',
		height: '100vh',
	},
	logo: {
		width: 180,
		height: 'auto',
		borderRadius: 200,
		marginBottom: theme.spacing(2),
	},
	disabledButton: {
		opacity: 0.5,
	},
}))
