import React from 'react'
import { makeStyles } from '@material-ui/core'
import { SearchInput } from 'components'

const SearchForm = ({
	value,
	onKeyPress,
	handleChange,
	...props
}) => {
	const classes = useStyles()
	return (
		<SearchInput
			fullWidth
			className={classes.root}
			name="keywords"
			value={value}
			handleChange={handleChange}
			onKeyPress={onKeyPress}
			autoFocus
		/>
	)
}

export default SearchForm

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
}))
