import React, { useState, useEffect } from 'react'
import { useClubs } from 'hooks'
import {
	IconButton,
	makeStyles,
} from '@material-ui/core'
import { Explore, Search } from '@material-ui/icons'
import GoogleMap from 'containers/clubs/GoogleMap'
import SearchInput from 'containers/clubs/SearchInput'
import CompassIcon from 'assets/icons/compass.svg'
import CompassActiveIcon from 'assets/icons/compass-active.svg'

const Map = () => {
	const LONDON = {
		lat: 51.5285578,
		lng: -0.2420215,
	}

	const [googleMap, setGoogleMap] = useState(null)
	const [mouseOver, setMouseOver] = useState(false)

	const DEFAULT_ZOOM = 8
	const PER_PAGE = 50

	const classes = useStyles()
	const [keywords, setKeywords] = useState('')
	const [markers, setMarkers] = useState()
	const [activeMarker, setActiveMarker] =
		useState(null)

	const { isLoading, clubs, findClubs } = useClubs({})

	const handleKeywordsChange = (e) => {
		setKeywords(e.target.value)
	}

	const handleKeyPress = (e) => {
		if (e.code === 'Enter') {
			handleSearch()
		}
	}

	const handleSearch = () => {
		findClubs({
			keywords: keywords,
			page: 1,
			per_page: PER_PAGE,
		})
	}

	const handleMarkers = (clubs) => {
		let mapMarkers = []
		clubs.forEach((club) => {
			mapMarkers.push({
				id: club.id,
				name: club.name,
				image: club.image_url,
				address: [
					club.main_ground,
					club.city,
					club.country,
				].join(', '),
				level: club.level,
				position: {
					lat: parseFloat(club.lat),
					lng: parseFloat(club.lng),
				},
			})
		})
		setMarkers(mapMarkers)
	}

	const handleRecenter = () => {
		googleMap.panTo(LONDON)
		googleMap.setZoom(8)
		setMouseOver(true)
		setTimeout(() => setMouseOver(false), 1000)
	}

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return
		}
		setActiveMarker(marker)
	}

	useEffect(() => {
		if (clubs) {
			handleMarkers(clubs)
		}
	}, [clubs])

	useEffect(() => {
		findClubs({
			page: 1,
			per_page: PER_PAGE,
		})
	}, [])

	return (
		<div className={classes.root}>
			<GoogleMap
				markers={markers}
				center={LONDON}
				zoom={DEFAULT_ZOOM}
				activeMarker={activeMarker}
				setActiveMarker={setActiveMarker}
				handleActiveMarker={handleActiveMarker}
				googleMap={googleMap}
				setGoogleMap={setGoogleMap}
			/>
			<div className={classes.search}>
				<div className={classes.spacer} />
				<div className={classes.searchBar}>
					<span>
						<IconButton
							onClick={handleSearch}
							size="small"
						>
							<Search className={classes.icon} />
						</IconButton>
					</span>
					<SearchInput
						placeholder="SEARCH"
						value={keywords}
						handleChange={handleKeywordsChange}
						onKeyPress={handleKeyPress}
					/>
				</div>
				<IconButton
					size="small"
					onClick={handleRecenter}
				>
					<img
						onMouseOver={() => setMouseOver(true)}
						onMouseOut={() => setMouseOver(false)}
						src={
							mouseOver
								? CompassActiveIcon
								: CompassIcon
						}
						className={classes.compassIcon}
					/>
				</IconButton>
			</div>
		</div>
	)
}

export default Map

const useStyles = makeStyles((theme) => ({
	root: {},
	search: {
		position: 'fixed',
		bottom: 50,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		zInded: theme.zIndex.modal + 1,
		[theme.breakpoints.down('sm')]: {
			top: 100,
			bottom: 'auto',
		},
	},
	searchBar: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: theme.spacing(0, 2),
		borderRadius: 50,
		width: 400,
		backgroundColor: theme.palette.background.main,
		opacity: 0.8,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: theme.spacing(1),
		},
	},
	spacer: {
		width: 60,
		[theme.breakpoints.down('sm')]: {
			width: 0,
		},
	},
	compassIcon: {
		height: 60,
		width: 60,
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.secondary.main,
		},
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			bottom: 20,
			right: 20,
			height: 60,
			width: 60,
		},
	},
}))
