import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUsers, useAlerts } from 'hooks'
import {
	Box,
	Button,
	Typography,
	Container,
	makeStyles,
} from '@material-ui/core'
import UserForm from 'containers/users/UserForm'
import { StickyButton } from 'components'
import moment from 'moment'

const UserEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const classes = useStyles()

	const {
		user,
		handleChange,
		updateUser,
		findUser,
		uploadFile,
		deleteFile,
	} = useUsers({ id: id })

	const { showAlertSuccess, showAlertError } =
		useAlerts()

	const handleSaveClick = async () => {
		await updateUser({
			...user,
			dob: moment(user.dob),
		})
		showAlertSuccess('Your profile has been updated')
		history.push(`/users/${user.id}`)
	}

	useEffect(() => {
		if (id) findUser(id)
	}, [])

	return (
		<>
			<Container>
				<Box p={1}>
					<Typography
						color="textPrimary"
						variant="h4"
						className={classes.title}
					>
						EDIT PROFILE
					</Typography>
					<UserForm
						user={user}
						handleChange={handleChange}
					/>
				</Box>
			</Container>
			<StickyButton>
				<Button
					fullWidth
					color="primary"
					variant="contained"
					onClick={handleSaveClick}
				>
					Save
				</Button>
			</StickyButton>
		</>
	)
}

export default UserEdit

const useStyles = makeStyles((theme) => ({
	title: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(3),
	},
}))
