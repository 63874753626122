import React, { useEffect } from 'react'
import {
	Typography,
	makeStyles,
} from '@material-ui/core'
import CourseItem from 'containers/courses/CourseItem'
import Carousel from 'react-material-ui-carousel'
import { useResponsive } from 'hooks'
import {
	ChevronLeft,
	ChevronRight,
} from '@material-ui/icons'

const CourseCarousel = ({
	courses,
	label,
	handleClick,
	...props
}) => {
	const classes = useStyles()

	const { isTablet, isMobile } = useResponsive()

	let numItemsPerRow = 1
	if (isMobile) {
		numItemsPerRow = 1
	} else if (isTablet) {
		numItemsPerRow = 2
	} else {
		numItemsPerRow = 3
	}

	let courseGroup = []
	if (courses.length > 0) {
		for (
			let i = 0;
			i < courses.length;
			i = i + numItemsPerRow
		) {
			courseGroup[i / numItemsPerRow] = courses.slice(
				i,
				Math.min(i + numItemsPerRow, courses.length)
			)
		}
	}

	const hideCarouselNavArrows =
		courses?.length <= 3 ||
		(isMobile && courses?.length <= 1)
			? true
			: false

	return (
		<div className={classes.root}>
			<center className={classes.subtitle}>
				<Typography variant="h3">{label}</Typography>
			</center>
			<Carousel
				autoPlay={false}
				navButtonsAlwaysVisible
				PrevIcon={
					<ChevronLeft className={classes.prevIcon} />
				}
				NextIcon={
					<ChevronRight
						className={classes.nextIcon}
					/>
				}
				navButtonsWrapperProps={
					styles.navButtonsWrapper
				}
				navButtonsProps={styles.navButtons}
				navButtonsAlwaysInvisible={
					hideCarouselNavArrows
				}
				animation={isMobile ? 'slide' : 'fade'}
			>
				{courseGroup?.map((courseRow, i) => (
					<div key={i} className={classes.row}>
						{courseRow.map((course, k) => (
							<CourseItem
								key={course.id}
								course={course}
								handleClick={handleClick}
							/>
						))}
					</div>
				))}
			</Carousel>
		</div>
	)
}

export default CourseCarousel

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 480,
	},
	row: {
		marginLeft: 90,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		[theme.breakpoints.down('md')]: {
			margin: 0,
			justifyContent: 'center',
		},
	},
	subtitle: {
		marginTop: 20,
		marginBottom: 20,
	},
	prevIcon: {
		height: 44,
		width: 44,
	},
	nextIcon: {
		height: 44,
		width: 44,
	},
}))

const styles = {
	navButtonsWrapper: {
		style: {
			bottom: '80px',
			top: 'unset',
		},
	},
	navButtons: {
		style: {
			backgroundColor: 'transparent',
		},
	},
}
