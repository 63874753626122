import React, { useEffect, useState } from 'react'
import {
	useAuth,
	usePaywall,
	useResponsive,
	useTopics,
	useVideo,
} from 'hooks'
import {
	Grid,
	Box,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import EditorialCover from 'containers/editorials/EditorialCover'
import EditorialItem from 'containers/editorials/EditorialItem'
import { VideoModal } from 'components'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'

const TopicShow = ({ ...props }) => {
	const { id } = props.match.params
	const classes = useStyles()

	const { currentUser } = useAuth()
	const { topic, findTopic } = useTopics({
		id: id,
	})

	const { isMobile } = useResponsive()

	const {
		activeVideo,
		videoOpen,
		playVideo,
		stopVideo,
	} = useVideo()

	const {
		isPaid,
		openPaywall,
		showPaywall,
		hidePaywall,
	} = usePaywall()

	const isLocked = currentUser?.id && !isPaid

	const handleEditorialClick = (editorial) => {
		if (!isPaid && editorial.premium) {
			showPaywall()
		} else {
			playVideo(editorial)
		}
	}

	useEffect(() => {
		if (id) findTopic(id)
	}, [])

	return (
		<div>
			<center className={classes.subheader}>
				<img
					src={topic.web_image2_url}
					className={classes.logo}
				/>
				<Typography
					variant="subtitle1"
					color="primary"
				>
					{topic?.description}
				</Typography>
			</center>
			<Container maxWdith="lg">
				{topic?.editorials?.length > 0 && (
					<Box my={3}>
						{isMobile ? (
							<EditorialItem
								editorial={topic.editorials[0]}
								handleClick={() =>
									handleEditorialClick(
										topic.editorials[0]
									)
								}
								isLocked={
									!isPaid &&
									topic.editorials[0].premium
								}
							/>
						) : (
							<EditorialCover
								editorial={topic.editorials[0]}
								handleClick={handleEditorialClick}
							/>
						)}
					</Box>
				)}
				<Grid container spacing={1}>
					{topic?.editorials
						?.slice(1)
						.map((editorial, i) => (
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								lg={4}
								key={i}
							>
								<EditorialItem
									editorial={editorial}
									handleClick={() =>
										handleEditorialClick(editorial)
									}
									isLocked={
										!isPaid && editorial.premium
									}
								/>
							</Grid>
						))}
				</Grid>
				<VideoModal
					src={activeVideo?.src}
					videoId={activeVideo?.videoId}
					captions={activeVideo?.captions}
					title={activeVideo?.title}
					open={videoOpen}
					handleClose={stopVideo}
				/>
				<Box p={4} />
			</Container>
			<PremiumSignupModal
				open={openPaywall}
				handleClose={hidePaywall}
			/>
		</div>
	)
}

export default TopicShow

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	subheader: {
		padding: theme.spacing(1),
		margin: theme.spacing(4, 1, 4, 1),
	},
	logo: {
		width: '100%',
		height: 'auto',
		maxWidth: 400,
	},
}))
