import React from 'react'
import {
	List,
	makeStyles,
	Typography,
} from '@material-ui/core'
import TeamItem from 'containers/teams/TeamItem'

const TeamList = ({ teams, handleClick }) => {
	const classes = useStyles()
	return (
		<List className={classes.root}>
			{teams?.length > 0 ? (
				teams.map((team, i) => (
					<TeamItem
						key={i}
						team={team}
						onClick={handleClick}
					/>
				))
			) : (
				<Typography
					variant="body2"
					className={classes.empty}
				>
					No teams added yet.
				</Typography>
			)}
		</List>
	)
}

export default TeamList

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: 0,
		borderTop: `1px solid ${theme.palette.primary.light}`,
	},
	empty: {
		margin: theme.spacing(1),
	},
}))
