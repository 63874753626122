import React, { useState, useEffect } from 'react'
import { fade } from '@material-ui/core'
import PinInput from 'react-pin-input'
import { syntheticEvent } from 'lib/utils'
import { muiTheme } from 'theme'
import {
	Box,
	Typography,
	createTheme,
} from '@material-ui/core'
const theme = createTheme(muiTheme)

const ReactPinInput = ({
	name,
	value,
	label,
	handleChange,
	length = 6,
}) => {
	const handlePinChange = (value, index) => {
		let ev = syntheticEvent(value, name)
		handleChange(ev)
	}

	return (
		<Box>
			{label && (
				<Box mb={1}>
					<Typography variant="body2">
						{label}
					</Typography>
				</Box>
			)}
			<PinInput
				length={length}
				initialValue={value}
				onChange={handlePinChange}
				type="numeric"
				inputMode="number"
				style={styles.root}
				inputStyle={styles.input}
				inputFocusStyle={{
					...styles.input,
					...styles.inputFocus,
				}}
				onComplete={handlePinChange}
				autoSelect={true}
				regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
			/>
		</Box>
	)
}

export default ReactPinInput

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	input: {
		width: '100%',
		webkitAppearance: 'none',
		MozAppearance: 'none',
		appearance: 'none',
		backgroundColor: 'transparent',
		padding: theme.spacing(1, 1.5),
		minHeight: 28,
		height: 44,
		color: theme.palette.text.primary,
		transition: theme.transitions.create([
			'border-color',
			'box-shadow',
		]),
		border: 'none',
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		...theme.typography.subtitle1,
	},
	inputFocus: {
		webkitAppearance: 'none',
		MozAppearance: 'none',
		borderBottom: `1px solid ${theme.palette.primary.main}`,
	},
}
