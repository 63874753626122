import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
	useBenefits,
	useAlerts,
	usePaywall,
} from 'hooks'
import {
	Button,
	Container,
	makeStyles,
} from '@material-ui/core'
import { StickyButton, CoverImage } from 'components'
import BenefitDetails from 'containers/benefits/BenefitDetails'
import PromotionModal from 'containers/promotions/PromotionModal'

const BenefitShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params
	const classes = useStyles()

	const [open, setOpen] = useState(false)

	const { currentUser, isPaid } = usePaywall()

	const { isLoading, benefit, findBenefit } =
		useBenefits({
			id: id,
		})

	const handleClick = () => {
		if (benefit.is_promotion) {
			setOpen(true)
		} else {
			window.open(benefit.url, '_blank')
		}
	}

	useEffect(() => {
		if (id) findBenefit(id)
	}, [])

	useEffect(() => {
		if (isPaid == false && currentUser?.id) {
			history.push('/home')
		}
	}, [isPaid, currentUser])

	return (
		<>
			<CoverImage
				height={520}
				desktopImageUrl={benefit.web_image_url}
				mobileImageUrl={benefit.image_url}
			/>
			<Container maxWidth="md">
				<BenefitDetails
					benefit={benefit}
					handleClick={handleClick}
				/>
			</Container>
			<PromotionModal
				open={open}
				benefit={benefit}
				handleClose={() => setOpen(false)}
			/>
			<StickyButton>
				<Button
					fullWidth
					className={classes.button}
					onClick={handleClick}
					variant="contained"
					color="primary"
				>
					{benefit.is_promotion
						? 'REDEEM'
						: 'VISIT WEBSITE'}
				</Button>
			</StickyButton>
		</>
	)
}

export default BenefitShow

const useStyles = makeStyles((theme) => ({
	root: {},
}))
