import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import mobile from 'is-mobile';
import {Close} from '@material-ui/icons';

const Modal = ({
  open,
  handleClose,
  icon,
  title,
  subtitle,
  actions: buttons,
  children,
  maxWidth,
  fullScreen,
  showClose = true,
  showBorder = false,
}) => {
  const classes = useStyles ({showBorder});

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth={maxWidth || 'sm'}
      fullScreen={mobile () || fullScreen === true ? true : false}
      open={open}
      onClose={handleClose}
    >
      <div className={classes.border}>
        <div className={classes.dialogTitle}>
          <Box justifyContent="space-between">

            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>

            {showClose &&
              <Box>
                <IconButton
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Box>}
          </Box>
        </div>
        <div>
          {subtitle &&
            <Typography variant="body1" className={classes.subtitle}>
              {subtitle}
            </Typography>}
          <div className={classes.buttons}>{children}</div>
        </div>
        {buttons && <DialogActions >{buttons}</DialogActions>}
      </div>
    </Dialog>
  );
};

export default Modal;

const useStyles = makeStyles (theme => ({
  border: {
    borderRadius: 8,
    padding: theme.spacing (1),
    border: props =>
      props.showBorder && `1px solid ${theme.palette.secondary.main}`,
  },
  dialogTitle: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    margin: 0,
    width: '100%',
    alignContent: 'center',
    textAlign: 'center',
  },
  title:{
    padding:'5%'
  },
  subtitle:{
    padding:'5%',
    textAlign:'center'
  },
  buttons:{
    paddingRight:'5%',
    paddingLeft:'5%',
    paddingBottom:'5%'

  },
  closeButton: {
    color: theme.palette.common.white,
    margin: 0,
  },
  closeIcon: {
    color: 'white !important',
  },
}));
