import React from 'react'
import { useUsers, useAuth, useAlerts } from 'hooks'
import { AuthScreen, TextInput } from 'components'
import {
	Link,
	Button,
	Box,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { validateParent } from 'validations/users'
import { useHistory } from 'react-router'

const ParentalApprovalRequest = ({ ...props }) => {
	const classes = useStyles()
	const { showAlertError, showAlertSuccess } =
		useAlerts()
	const history = useHistory()

	const {
		isLoading,
		user: parent,
		handleChange,
		requestParentApproval,
	} = useUsers()

	const { currentUser } = useAuth()

	const handleSubmit = async () => {
		let valid = validateParent(parent)
		if (valid.isValid) {
			await requestParentApproval(
				currentUser?.id,
				parent
			)
			showAlertSuccess(
				`You have successfully requested approval`
			)
			history.push('/parental_approval/pending')
		} else {
			valid.messages.map((m) => showAlertError(m))
		}
	}

	return (
		<div>
			<AuthScreen title="Parental Approval">
				<Typography
					variant="subtitle1"
					className={classes.notification}
				>
					All users under the age of 18 must have
					parental approval to create an account.
					<br />
					<br />
					Please enter your parent/guardians name and
					email address below.
				</Typography>
				<Grid container spacing={0}>
					<Grid item xs={12} sm={12}>
						<TextInput
							name="first_name"
							value={parent.first_name}
							placeholder="FIRST NAME"
							handleChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextInput
							name="last_name"
							value={parent.last_name}
							placeholder="LAST NAME"
							handleChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TextInput
							border
							name="email"
							value={parent.email}
							placeholder="EMAIL"
							handleChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<center>
							<Box width={244} my={2}>
								<Button
									fullWidth
									color="primary"
									onClick={handleSubmit}
									variant="contained"
								>
									Next
								</Button>
							</Box>
						</center>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Box my={4} />
						<center>
							<Typography variant="body1">
								Already have an account?
							</Typography>
							<Typography variant="body1">
								<Link color="secondary" href="/login">
									Sign In
								</Link>
							</Typography>
						</center>
					</Grid>
				</Grid>
			</AuthScreen>
		</div>
	)
}

export default ParentalApprovalRequest

const useStyles = makeStyles((theme) => ({
	notification: {
		fontSize: 13,
		padding: theme.spacing(2, 0),
		textTransform: 'uppercase',
		color: theme.palette.text.primary,
		borderTop: `1px solid ${theme.palette.primary.light}`,
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		whiteSpace: 'pre-line',
	},
	buttons: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	button: {
		maxWidth: 200,
		marginBottom: 5,
	},
}))
