import React, { useState, useEffect } from 'react'
import ProgressBar from 'containers/skills/ProgressBar'
import { makeStyles } from '@material-ui/core'
import { SKILLS } from 'lib/constants'

const UserProgressBars = ({
	user,
	showAll = false,
}) => {
	const classes = useStyles()
	const [skills, setSkills] = useState()

	useEffect(() => {
		if (user) {
			handleCalcSkills()
		}
	}, [user])

	const handleCalcSkills = () => {
		const userSkills = SKILLS.map((skill) => {
			const totalPoints =
				user[`${skill.value}_points`]
			const level = Math.floor(totalPoints / 10)
			const points = totalPoints % 10
			const icon = skill?.value
			return {
				...skill,
				level,
				points,
				icon,
			}
		}).sort((a, b) => b.level - a.level)
		setSkills(userSkills)
	}

	return (
		<div className={classes.root}>
			{skills &&
				skills.map((skill, i) => {
					if (!showAll && i > 2) return null
					return (
						<ProgressBar
							key={skill.value}
							label={skill.label}
							secondaryLabel={`Level ${skill.level}`}
							maxPoints={10}
							icon={skill.icon}
							points={skill.points}
						/>
					)
				})}
		</div>
	)
}

export default UserProgressBars

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100vw - 20px)',
		},
	},
}))
