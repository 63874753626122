import React from 'react'
import {
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { COUNTRIES } from 'lib/constants/countries'
import clsx from 'clsx'

const ClubDetails = ({ club }) => {
	const classes = useStyles()

	return (
		<Grid container spacing={1}>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderRight
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						COUNTRY/REGION
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{
							COUNTRIES.find(
								(c) => c.value == club.country
							)?.label
						}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						LOCATION
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{club.city ? club.city : '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						LEVEL
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{club.level ? club.level : '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						MAIN GROUND
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{club?.main_ground
							? club.main_ground
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						TRAINING GROUND
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{club?.training_ground
							? club.training_ground
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						CONTACT REPRESENTATIVE
					</Typography>
					<Typography
						variant="body1"
						className={clsx(
							classes.title,
							classes.capitalize
						)}
					>
						{club.secretary ? club.secretary : '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						WEBSITE
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{club.website ? club.website : '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						CONTACT EMAIL
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{club.contact_email
							? club.contact_email
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(
					classes.borderTop,
					classes.borderBottom
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						CONTACT NUMBER
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{club.contact_number
							? club.contact_number
							: '-'}
					</Typography>
				</div>
			</Grid>
		</Grid>
	)
}

export default ClubDetails

const useStyles = makeStyles((theme) => ({
	switch: {
		display: 'flex',
		paddingLeft: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	caption: {
		opacity: 0.7,
	},
	cell: {
		padding: theme.spacing(0, 1),
	},
	borderTop: {
		borderTop: `1px solid ${theme.palette.primary.light}`,
	},
	borderRight: {
		borderRight: `1px solid ${theme.palette.primary.light}`,
	},
	borderBottom: {
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
	capitalize: {
		textTransform: 'capitalize',
	},
}))
