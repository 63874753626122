import React, { useContext, useEffect } from 'react'
import { useUsers, useLessons } from 'hooks'
import { AppContext } from 'context'
import {
	Container,
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { StickyButton } from 'components'
import Cone from 'assets/icons/cone2.svg'

const LessonComplete = (props) => {
	const history = useHistory()
	const { id } = props.match.params

	const classes = useStyles()
	const { currentUser } = useContext(AppContext)
	const { isLoaded, lesson, findLesson } = useLessons(
		{ id: id }
	)
	const { completeLesson, viewLesson } = useUsers({
		id: 'me',
	})

	const handleViewLesson = async () => {
		await findLesson(id)
		const resp = await viewLesson(id)
		if (
			resp?.completed_course_ids?.includes(
				lesson.course_id
			)
		) {
			history.push(
				`/courses/${lesson.course_id}/lessons/${lesson.id}/course_complete`
			)
		}
	}

	const handleClick = () => {
		if (
			currentUser.viewed_lesson_ids.includes(
				lesson?.id
			)
		) {
			history.push(
				`/courses/${lesson.course_id}/lessons/${lesson.id}`
			)
		} else {
			history.push(
				`/courses/${lesson.course_id}/lessons/${lesson.id}/progress`
			)
		}
	}

	useEffect(() => {
		if (lesson?.id) {
			handleViewLesson()
		}
	}, [lesson?.id])

	useEffect(() => {
		if (id) {
			findLesson(id)
			completeLesson(id)
		}
	}, [id])

	return (
		<div>
			<Container>
				<div className={classes.container}>
					<div></div>
					<div>
						<center>
							<img
								src={Cone}
								className={classes.icon}
							/>
						</center>
						<Typography
							variant="subtitle1"
							color="secondary"
							className={classes.caption}
						>
							{lesson.title}
						</Typography>
						<Typography
							variant="h1"
							className={classes.title}
						>
							SESSION COMPLETE
						</Typography>
						<Typography
							variant="subtitle1"
							color="secondary"
							className={classes.caption}
						>
							CONGRATULATIONS
						</Typography>
						<center>
							<Typography
								variant="subtitle1"
								color="primary"
								className={classes.body}
							>
								Well done on completing Session{' '}
								{lesson.position} of this special
								edition training course with{' '}
								{lesson?.course?.trainer?.name}.
								<br />
								<br />
								Record and upload yourself completing
								what you’ve just watched to level up
								your skills and share it with
								teammates, coaches and scouts.
							</Typography>
						</center>
					</div>
				</div>
			</Container>
			<StickyButton>
				<Button
					fullWidth
					variant="contained"
					onClick={handleClick}
					color="primary"
				>
					NEXT
				</Button>
			</StickyButton>
		</div>
	)
}

export default LessonComplete

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 200,
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	icon: {
		width: 80,
		height: 'auto',
	},
	caption: {
		margin: theme.spacing(2, 0),
		textTransform: 'uppercase',
		fontWeight: 600,
	},
	title: {
		color: theme.palette.common.white,
		fontSize: 200,
		[theme.breakpoints.down('xs')]: {
			fontSize: 64,
		},
	},
	body: {
		marginTop: theme.spacing(2),
		maxWidth: 900,
		fontWeight: 500,
		fontSize: 20,
		lineHeight: '2.5em',
	},
	button: {
		marginTop: theme.spacing(2),
		width: '100%',
		maxWidth: 320,
	},
}))
