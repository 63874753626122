import React, { useState, useEffect } from 'react'
import { useVideos } from 'hooks'
import {
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { TextInput } from 'components'
import clsx from 'clsx'
import VideoTags from 'containers/videos/VideoTags'

const MAX_CHARS = 200

const VideoForm = ({
	video,
	activeTags,
	handleTagClick,
	handleChange,
}) => {
	const [tags, setTags] = useState([])

	const { findTags } = useVideos({})

	const fetchTags = async () => {
		let tags = await findTags()
		setTags(tags)
	}

	useEffect(() => {
		fetchTags()
	}, [])

	const handleTextChange = (ev) => {
		const { value } = ev.target
		if (value?.length <= MAX_CHARS) {
			handleChange(ev)
		}
	}

	const classes = useStyles()
	return (
		<div>
			<div className={classes.divider} />
			<div className={classes.description}>
				<TextInput
					rows={12}
					multiline
					border={false}
					noBorder
					value={video.description}
					name="description"
					placeholder="Describe your video ..."
					handleChange={handleTextChange}
				/>
			</div>
			<div className={classes.counter}>
				<Typography variant="body2" color="secondary">
					{video?.description?.length || 0}/
					{MAX_CHARS}
				</Typography>
			</div>
			<Typography
				variant="body2"
				className={classes.label}
			>
				Select tags
			</Typography>
			<VideoTags
				tags={tags}
				activeTags={activeTags}
				handleClick={handleTagClick}
			/>
		</div>
	)
}

export default VideoForm

const useStyles = makeStyles((theme) => ({
	root: {},
	description: {
		marginTop: 90,
		height: 150,
	},
	divider: {
		height: 20,
		borderTop: `1px solid ${theme.palette.primary.light}`,
	},
	counter: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexScale: 1,
		marginTop: -40,
		marginBottom: 20,
	},
	label: {
		marginLeft: 5,
	},
}))
