import React, { useEffect, useState } from "react";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import Image from "assets/images/login-background.png";
import CustomModal from "../../components/CustomModal";
import { useHistory, useLocation } from "react-router-dom";
import { usePaywall } from "hooks";
import queryString from "query-string";

const NicePaySuccessScreen = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(window.location.pathname);
  const { isLoading, nicePayVerifySuccess } = usePaywall();

  const searchParams = queryString.parse(location.search);

  useEffect(async () => {
    console.log(searchParams.referenceNo);
    if (!searchParams.referenceNo&&searchParams.success!=1) {
      history.push(`/home`);
    } else {
      const resp = await nicePayVerifySuccess(searchParams.referenceNo);
    }
  }, []);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div />
      ) : (
        <CustomModal
          title={"Payment Successful!"}
          msg={`We are delighted to inform you that we received your payment!`}
          buttonLabel={"Continue to the app"}
          handleClose={() => history.push("/home")}
          open={true}
        />
      )}
    </div>
  );
};

export default NicePaySuccessScreen;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    background: `url(${Image}) center center no-repeat`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
