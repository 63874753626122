import React from 'react'
import {
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import {
	Autosuggest,
	SwitchInput,
	TextInput,
	DateInput,
	ClubAutosuggest,
} from 'components'
import {
	PREFERRED_FOOT,
	POSITIONS,
} from 'lib/constants'
import { COUNTRIES } from 'lib/constants/countries'
import clsx from 'clsx'
import moment from 'moment'

const UserForm = ({
	user,
	isEditing,
	handleChange,
}) => {
	const handlePositionChange = (ev, index) => {
		const { name, value } = ev.target
		let positions = [...user.positions]
		positions[index] = value
		handleChange({
			target: {
				name: 'positions',
				value: positions,
			},
		})
	}

	const classes = useStyles()
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} sm={12}>
				<Typography variant="h5">
					EDIT NAME
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={clsx(
					classes.borderRight,
					classes.borderTopNoHide
				)}
			>
				<TextInput
					borderBottom
					value={user.first_name}
					label="First name"
					name="first_name"
					placeholder="FIRST NAME"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={classes.borderTop}
			>
				<TextInput
					borderBottom
					value={user.last_name}
					label="Last name"
					name="last_name"
					placeholder="LAST NAME"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Typography
					variant="h5"
					className={classes.subtitle}
				>
					ATTRIBUTES
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={clsx(
					classes.borderRight,
					classes.borderTopNoHide
				)}
			>
				<Autosuggest
					options={COUNTRIES}
					value={user.country}
					label="Country/Region"
					name="country"
					placeholder="COUNTRY/REGION"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={clsx(classes.borderTop)}
			>
				<Autosuggest
					options={COUNTRIES}
					value={user.passport_country}
					label="Nationality"
					name="passport_country"
					placeholder="NATIONALITY"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={clsx(classes.borderRight)}
			>
				<DateInput
					borderBottom
					value={
						user.dob
							? moment(user.dob).format('MM/DD/YYYY')
							: user.dob
					}
					label="Date of Birth"
					name="dob"
					placeholder="DATE OF BIRTH"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Autosuggest
					value={user.preferred_foot}
					label="Preferred foot"
					name="preferred_foot"
					placeholder="PREFERRED FOOT"
					options={PREFERRED_FOOT}
					handleChange={handleChange}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={classes.borderRight}
			>
				<TextInput
					borderBottom
					value={user.height}
					label="Height (CM)"
					name="height"
					placeholder="HEIGHT (CM)"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					borderBottom
					value={user.weight}
					label="Weight (KG)"
					name="weight"
					placeholder="WEIGHT (KG)"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={classes.borderRight}
			>
				<TextInput
					borderBottom
					value={user.shirt}
					label="Shirt number"
					name="shirt"
					placeholder="SHIRT NUMBER"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					borderBottom
					value={user.favorite_club}
					label="Favorite club"
					name="favorite_club"
					placeholder="FAVORITE CLUB"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={classes.borderRight}
			>
				<TextInput
					borderBottom
					value={user.favorite_boot}
					label="Favorite boot"
					name="favorite_boot"
					placeholder="FAVORITE BOOT"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextInput
					borderBottom
					value={user.favorite_brand}
					label="Favorite brand"
					name="favorite_brand"
					placeholder="FAVORITE BRAND"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<ClubAutosuggest
					value={user.club_id}
					label="Current club"
					name="club_id"
					placeholder="CURRENT CLUB"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Typography
					variant="h5"
					className={classes.subtitle}
				>
					POSITIONS
				</Typography>
			</Grid>
			{[0, 1, 2].map((i) => (
				<Grid
					item
					xs={12}
					key={i}
					className={clsx({
						[classes.borderTopNoHide]: i == 0,
					})}
				>
					<Autosuggest
						options={POSITIONS}
						value={
							user?.positions ? user.positions[i] : ''
						}
						label="Position"
						name="position"
						placeholder="POSITION"
						handleChange={(e) =>
							handlePositionChange(e, i)
						}
					/>
				</Grid>
			))}
			<Grid item xs={12} sm={12}>
				<Typography
					variant="h5"
					className={classes.subtitle}
				>
					STATUS
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={clsx(
					classes.borderTopNoHide,
					classes.borderRight,
					classes.borderBottom
				)}
			>
				<div className={classes.switch}>
					<Typography variant="subtitle2">
						Agent
					</Typography>
					<SwitchInput
						name="agent"
						value={user.agent}
						handleChange={handleChange}
					/>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderBottom
				)}
			>
				<div className={classes.switch}>
					<Typography variant="subtitle2">
						Contract
					</Typography>
					<SwitchInput
						value={user.contract}
						name="contract"
						handleChange={handleChange}
					/>
				</div>
			</Grid>
		</Grid>
	)
}

export default UserForm

const useStyles = makeStyles((theme) => ({
	switch: {
		display: 'flex',
		paddingLeft: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	subtitle: {
		marginTop: 20,
	},
	borderTopNoHide: {
		borderTop: `1px solid ${theme.palette.primary.light}`,
	},
	borderTop: {
		borderTop: `1px solid ${theme.palette.primary.light}`,
		[theme.breakpoints.down('xs')]: {
			borderTop: 'none',
		},
	},
	borderRight: {
		borderRight: `1px solid ${theme.palette.primary.light}`,
		[theme.breakpoints.down('xs')]: {
			borderRight: 'none',
		},
	},
	borderBottom: {
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
}))
