import React from 'react'
import { List } from '@material-ui/core'
import LessonItem from 'containers/lessons/LessonItem'
import { orderBy } from 'lodash'

const LessonList = ({ lessons, handleClick }) => {
	return (
		<List>
			{lessons?.length > 0 &&
				orderBy(lessons, ['position', 'desc']).map(
					(lesson, i) => (
						<LessonItem
							key={i}
							lesson={lesson}
							handleClick={handleClick}
						/>
					)
				)}
		</List>
	)
}

export default LessonList
