import React from 'react'
import {
	Button,
	ListItem,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const MenuItem = ({
	handleClick,
	active,
	value,
	depth = 0,
	text,
	icon: Icon,
}) => {
	const classes = useStyles()

	let paddingLeft = 18

	if (depth > 0) {
		paddingLeft = 42 + 8 * depth
	}

	const style = {
		paddingLeft,
	}

	return (
		<ListItem
			className={classes.itemLeaf}
			disableGutters
		>
			<Button
				onClick={(e) => handleClick(value, e)}
				className={clsx(
					classes.button,
					`depth-${depth}`,
					active ? classes.active : null
				)}
				style={style}
				color="primary"
				startIcon={
					Icon && <Icon className={classes.icon} />
				}
			>
				<Typography
					variant="subtitle1"
					className={classes.label}
				>
					{text}
				</Typography>
			</Button>
		</ListItem>
	)
}
export default MenuItem

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'block',
	},
	itemLeaf: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0,
	},
	button: {
		borderRadius: '0',
		color: theme.palette.common.white,
		padding: '12px 8px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
	},
	icon: {
		height: 24,
		width: 24,
		color: theme.palette.common.white,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1),
	},
	expandIcon: {
		marginLeft: 'auto',
		height: 20,
		width: 20,
	},
	label: {
		fontSize: 18,
		textTransform: 'uppercase',
	},
	active: {
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightBold,
		'& $label': {
			color: theme.palette.secondary.main,
		},
		'& $icon': {
			color: theme.palette.secondary.main,
		},
	},
}))
