import React from 'react'
import api from 'api'
import { useResource } from 'hooks'

const useClubs = ({ id, ...props }) => {
	const {
		resourceId: clubId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: club,
		resources: clubs,
		findOne: findClub,
		findMany: findClubs,
		update: updateClub,
		create: createClub,
		save: saveClub,
		destroy: deleteClub,
		handleChange,
		handleChangePage,
		reload: reloadClub,
		reloadMany: reloadClubs,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResources: setClubs,
	} = useResource({
		id: id,
		url: '/api/v1/clubs',
		name: 'club',
	})

	return {
		clubId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		club,
		clubs,
		findClub,
		findClubs,
		saveClub,
		updateClub,
		createClub,
		deleteClub,
		handleChange,
		handleChangePage,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadClub,
		reloadClubs,
	}
}

export default useClubs
