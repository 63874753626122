import React, { useContext, useState } from 'react'
import { usePaywall } from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	KeyboardArrowDown,
	KeyboardArrowUp,
} from '@material-ui/icons'
import {
	IconButton,
	Collapse,
	ListItem,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { AppContext } from 'context'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'
import { resize } from 'lib/cloudinary'

const MENU_HEIGHT = 320

const ActiveTrainingMenu = ({ toggleMenu }) => {
	const {
		currentUser: { course, lesson },
	} = useContext(AppContext)

	const {
		isPaid,
		showPaywall,
		hidePaywall,
		openPaywall,
	} = usePaywall()

	const [active, setActive] = useState(false)
	const history = useHistory()

	const src = resize(course.web_image2_url, {
		height: MENU_HEIGHT,
	})

	const classes = useStyles({
		src: src,
	})

	const handleToggleActiveClick = () => {
		setActive(!active)
	}

	const handleActiveTrainingClick = () => {
		if (!isPaid && lesson.premium) {
			showPaywall()
		} else {
			toggleMenu()
			// Navigate to course or lesson
			if (lesson) {
				history.push(
					`/courses/${lesson.course_id}/lessons/${lesson?.id}`
				)
			} else {
				history.push(`/courses/${course?.id}`)
			}
		}
	}

	return (
		<ListItem
			button
			onClick={handleToggleActiveClick}
			className={classes.itemLeaf}
			disableGutters
		>
			<div className={classes.trainingContainer}>
				<div className={classes.titleWrapper}>
					<div className={classes.toggleButton}>
						<Typography
							variant="subtitle3"
							color="secondary"
							className={classes.subtitle}
						>
							ACTIVE TRAINING:
						</Typography>
						<IconButton
							className={classes.iconButton}
						>
							{active ? (
								<KeyboardArrowUp />
							) : (
								<KeyboardArrowDown />
							)}
						</IconButton>
					</div>
					<div className={classes.trainingTitle}>
						<Typography
							variant="h6"
							color="primary"
							className={classes.courseTitle}
						>
							{course.title}
						</Typography>
						<Typography
							variant="subtitle2"
							color="secondary"
							className={classes.activeTrainingName}
						>
							WITH {course?.trainer?.name}
						</Typography>
					</div>
				</div>
				<Collapse in={active}>
					<div
						onClick={handleActiveTrainingClick}
						className={classes.nextSessionWrapper}
					>
						<div className={classes.nextSessionImage}>
							<div
								className={classes.nextSession}
								onClick={handleActiveTrainingClick}
							>
								<div>
									<Typography
										variant="h6"
										color="secondary"
										className={classes.trainingName}
									>
										NEXT SESSION:
									</Typography>
									<Typography
										variant="h4"
										color="primary"
										className={classes.sessionText}
									>
										{lesson?.title}
									</Typography>
								</div>
								<div
									className={classes.sessionProgress}
								>
									<Typography
										variant="h6"
										color="secondary"
										className={classes.sessionText}
									>
										SESSION
									</Typography>
									<Typography
										variant="h3"
										color="primary"
										className={classes.sessionText}
									>
										{lesson?.position}/
										{course.lesson_count}
									</Typography>
								</div>
							</div>
						</div>
					</div>
				</Collapse>
			</div>
			<PremiumSignupModal
				open={openPaywall}
				handleClose={hidePaywall}
			/>
		</ListItem>
	)
}
export default ActiveTrainingMenu

const useStyles = makeStyles((theme) => ({
	itemLeaf: {
		overflow: 'hidden',
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0,
	},
	iconButton: {
		padding: 2,
	},
	toggleButton: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 12,
		lineHeight: 0.8,
	},
	courseTitle: {
		lineHeight: '1em',
	},
	trainingTitle: {
		marginLeft: 20,
		marginBottom: 14,
		marginTop: 8,
		lineHeight: '1em',
		cursor: 'pointer',
	},
	activeTrainingName: {
		textTransform: 'uppercase',
	},
	nextSessionWrapper: {
		height: 360,
		backgroundImage: (props) => `url(${props.src})`,
		backgroundSize: 'cover',
		maskImage:
			'linear-gradient(to bottom, rgba(0,0,0,0.05) 0%,rgba(0,0,0,1.0) 25%)',
	},
	nextSessionImage: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		backgroundImage: (props) => `url(${props.src})`,
		backgroundSize: 'cover',
		backgroundPosition: '50% 25%',
	},
	nextSession: {
		cursor: 'pointer',
		margin: theme.spacing(0, 0, 5, 0),
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 6,
		display: 'flex',
		justifyContent: 'space-between',
		width: '80%',
		alignSelf: 'center',
		backgroundColor: theme.palette.background.main,
	},
	sessionText: {
		lineHeight: 0.9,
	},
	titleWrapper: {
		width: '100%',
		zIndex: 9,
	},
	trainingContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	subtitle: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		textIndent: 0,
		fontSize: 22,
		textTransform: 'uppercase',
		lineHeight: '1em',
	},
	trainingName: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		textIndent: 0,
		fontSize: 20,
		textTransform: 'uppercase',
		lineHeight: '1em',
	},
	sessionProgress: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
	},
}))
