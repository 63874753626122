import React from 'react'
import {
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { COUNTRIES } from 'lib/constants/countries'
import clsx from 'clsx'
import moment from 'moment'

const UserDetails = ({ user }) => {
	const classes = useStyles()

	return (
		<Grid container spacing={1}>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderRight
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						COUNTRY/REGION
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{
							COUNTRIES.find(
								(c) => c.value == user.country
							)?.label
						}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						NATIONALITY
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.passport_country
							? user.passport_country
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderRight
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						DATE OF BIRTH
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.dob
							? moment(user.dob).format('YYYY')
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						PREFERRED FOOT
					</Typography>
					<Typography
						variant="body1"
						className={clsx(
							classes.title,
							classes.capitalize
						)}
					>
						{user.preferred_foot
							? user.preferred_foot
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderRight
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						HEIGHT
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.height ? `${user.height}cm` : '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						WEIGHT
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user?.weight ? `${user.weight}kg` : '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						POSITIONS
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user?.positions?.length > 0
							? user.positions.join(', ')
							: '-'}
					</Typography>
				</div>
			</Grid>

			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderRight
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						SHIRT NUMBER
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.shirt ? user.shirt : '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						FAVORITE CLUB
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.favorite_club
							? user.favorite_club
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderRight
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						FAVORITE BRAND
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.favorite_brand
							? user.favorite_brand
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(classes.borderTop)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						FAVORITE BOOT
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.favorite_boot
							? user.favorite_boot
							: '-'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderRight,
					classes.borderBottom
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						AGENT
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.agent ? 'Yes' : 'No'}
					</Typography>
				</div>
			</Grid>
			<Grid
				item
				xs={6}
				sm={6}
				className={clsx(
					classes.borderTop,
					classes.borderBottom
				)}
			>
				<div className={classes.cell}>
					<Typography
						variant="caption"
						className={classes.caption}
					>
						CONTRACT
					</Typography>
					<Typography
						variant="body1"
						className={classes.title}
					>
						{user.contract ? 'Yes' : 'No'}
					</Typography>
				</div>
			</Grid>
		</Grid>
	)
}

export default UserDetails

const useStyles = makeStyles((theme) => ({
	switch: {
		display: 'flex',
		paddingLeft: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	caption: {
		opacity: 0.7,
	},
	cell: {
		padding: theme.spacing(0, 1),
	},
	borderTop: {
		borderTop: `1px solid ${theme.palette.primary.light}`,
	},
	borderRight: {
		borderRight: `1px solid ${theme.palette.primary.light}`,
	},
	borderBottom: {
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
	capitalize: {
		textTransform: 'capitalize',
	},
}))
