import React from 'react'
import {
	Typography,
	makeStyles,
	Badge,
	Box,
} from '@material-ui/core'

const EventThumbnailDate = ({
	event,
	opacity = 1.0,
}) => {
	const { start_month, start_day } = event || {}
	const classes = useStyles({
		opacity,
	})
	return (
		<div className={classes.root}>
			<Typography
				variant="h2"
				className={classes.day}
			>
				{start_day}
			</Typography>
			<Typography
				variant="h6"
				className={classes.month}
			>
				{start_month}
			</Typography>
		</div>
	)
}

export default EventThumbnailDate

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: 10,
		width: 80,
		padding: 2,
		color: theme.palette.common.input,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 5,
		opacity: (props) => props.opacity,
	},
	day: {
		color: theme.palette.background.main,
		textTransform: 'uppercase',
		textAlign: 'center',
	},
	month: {
		color: theme.palette.background.main,
		fontSize: 20,
		textTransform: 'uppercase',
		textAlign: 'center',
	},
}))
