import React, { useState, useEffect } from 'react'
import { useAuth, useCourses } from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	Container,
	Grid,
	Button,
	Box,
	makeStyles,
} from '@material-ui/core'
import FeaturedCourses from 'containers/courses/FeaturedCourses'
import CourseBrowse from 'containers/courses/CourseBrowse'
import IntroVideoModal from 'containers/courses/IntroVideoModal'
import SearchFilterModal from 'containers/search/SearchFilterModal'
import NewCourseModal from 'containers/courses/NewCourseModal'
import FilterIcon from 'assets/icons/search-filter.svg'
import SearchIcon from 'assets/icons/search.svg'
import clsx from 'clsx'

const CourseList = ({ ...props }) => {
	const history = useHistory()

	const disableSearch = true

	const classes = useStyles()
	const [keywords, setKeywords] = useState('')
	const [showIntroVideo, setShowIntroVideo] =
		useState(false)
	const [showNewCourseModal, setShowNewCourseModal] =
		useState(false)

	const [showFilters, setShowFilters] =
		useState(false)
	const [newCourses, setNewCourses] = useState([])

	const defaultFilters = {
		tags: [],
		difficulty: null,
		trainer_ids: [],
	}

	const [filters, setFilters] = useState(
		defaultFilters
	)
	const [activeFilters, setActiveFilters] = useState(
		defaultFilters
	)

	const { currentUser, markViewedNotification } =
		useAuth()

	const {
		isLoading,
		isLoaded,
		courses,
		findCourses,
	} = useCourses({})

	const handleFilterChange = (name, value) => {
		setFilters({
			...filters,
			[name]: value,
		})
	}

	const handleSearch = () => {
		history.push('/search')
	}

	const handleApplyFilters = () => {
		setShowFilters(false)
		setActiveFilters(filters)
	}

	const handleResetFilters = () => {
		setFilters(defaultFilters)
		setActiveFilters(defaultFilters)
	}

	const handleCourseClick = (course) => {
		history.push(`/courses/${course.id}/enroll`)
	}

	const handleIntroVideo = () => {
		const viewedIntroVideo =
			!currentUser?.view_notifications?.includes(
				'video_intro'
			)
		if (currentUser?.id && !viewedIntroVideo) {
			setShowIntroVideo(true)
		}
	}

	const handleCloseNewCourseModal = async () => {
		setShowNewCourseModal(false)
		await markViewedNotification(
			`course_${newCourses[0].id}`
		)
		let recentCourses = [...newCourses]
		recentCourses.shift()
		setNewCourses(recentCourses)
		if (recentCourses?.length > 0) {
			setTimeout(
				() => setShowNewCourseModal(true),
				1000
			)
		}
	}

	useEffect(() => {
		const recentCourses = courses?.filter(
			(c) => c.recently_published === true
		)
		if (recentCourses.length > 0 && currentUser?.id) {
			const viewedNotifications =
				currentUser.viewed_notifications
			const filteredCourses = recentCourses.filter(
				(c) =>
					!viewedNotifications.includes(
						`course_${c.id}`
					)
			)
			setNewCourses(filteredCourses)
			setShowNewCourseModal(true)
		}
	}, [courses, currentUser?.viewed_notifications])

	useEffect(() => {
		findCourses({
			'courses.featured': true,
		})
	}, [])

	useEffect(() => {
		handleIntroVideo()
	}, [currentUser?.id])

	const { tags, difficulty, trainer_ids } =
		activeFilters || {}
	const hasActiveFilters =
		tags?.length > 0 ||
		difficulty ||
		trainer_ids?.length > 0

	return (
		<>
			{isLoaded && (
				<FeaturedCourses
					courses={courses}
					handleClick={handleCourseClick}
				/>
			)}
			<Container maxWidth="lg">
				{isLoaded && (
					<div>
						<Box className={classes.container}>
							<div
								className={classes.searchContainer}
							>
								<Grid container spacing={1}>
									<Grid item xs={6} sm={6}>
										<Button
											fullWidth
											className={classes.button}
											onClick={handleSearch}
											variant="outlined"
											color="secondary"
											endIcon={
												<img
													src={SearchIcon}
													className={classes.icon}
												/>
											}
										>
											Search
										</Button>
									</Grid>
									<Grid item xs={6} sm={6}>
										<Button
											fullWidth
											className={clsx(
												classes.button,
												{
													[classes.buttonSelected]:
														hasActiveFilters,
												}
											)}
											onClick={() =>
												setShowFilters(true)
											}
											variant="outlined"
											color="secondary"
											endIcon={
												<img
													src={FilterIcon}
													className={classes.icon}
												/>
											}
										>
											FILTER
										</Button>
									</Grid>
								</Grid>
							</div>
						</Box>
						<CourseBrowse filters={activeFilters} />
					</div>
				)}
			</Container>
			<IntroVideoModal
				open={showIntroVideo}
				handleClose={() => setShowIntroVideo(false)}
			/>
			<SearchFilterModal
				open={showFilters}
				handleClose={() => setShowFilters(false)}
				filters={filters}
				setFilters={setFilters}
				handleFilterChange={handleFilterChange}
				handleSubmit={handleApplyFilters}
				handleResetFilters={handleResetFilters}
			/>
			{newCourses?.length > 0 && (
				<NewCourseModal
					open={showNewCourseModal}
					course={newCourses && newCourses[0]}
					handleClose={handleCloseNewCourseModal}
				/>
			)}
		</>
	)
}

export default CourseList

const useStyles = makeStyles((theme) => ({
	button: {
		width: '100%',
		color: theme.palette.common.white,
		marginTop: theme.spacing(1),
		backgroundColor: `#171717`,
	},
	buttonSelected: {
		backgroundColor: theme.palette.secondary.main,
	},
	searchContainer: {
		padding: `0 40px 0 50px`,
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
		},
	},
	container: {
		margin: theme.spacing(6, 8, 0, 6),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(4, 0, 0, 0),
		},
	},
}))
