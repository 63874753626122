import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Modal, VideoPlayer } from 'components'

const VideoModal = ({
	open,
	src,
	videoId,
	captions,
	title,
	handleClose,
	autoPlay = true,
	actions,
	...props
}) => {
	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title={title}
			fullScreen
			actions={actions}
		>
			{open && (
				<VideoPlayer
					src={src}
					videoId={videoId}
					captions={captions}
					autoPlay={autoPlay}
					onComplete={handleClose}
				/>
			)}
		</Modal>
	)
}

export default VideoModal

const useStyles = makeStyles((theme) => ({
	root: {},
	video: {},
}))
