/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { useAlerts, useLoaders } from 'hooks'
import api from 'api'

const useHome = (props) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError } = useAlerts()

	const [data, setData] = useState()

	const loadHome = async () => {
		try {
			showLoading()
			let resp = await api.get('/api/v1/home')
			if (resp && resp.data) {
				setData(resp.data)
			}
			hideLoading()
			return resp.data
		} catch (e) {
			hideLoading()
			showAlertError('There was an error', e)
		}
	}

	return {
		data,
		loadHome,
	}
}

export default useHome
