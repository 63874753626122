import { colors } from '@material-ui/core'

export default {
	primary: {
		contrastText: '#000000',
		main: '#FFFFFF',
		light: '#666666',
	},
	secondary: {
		contrastText: '#000000',
		main: '#8DDDA5',
	},
	error: {
		contrastText: '#ffffff',
		dark: colors.pink[900],
		main: colors.pink[600],
		light: colors.pink[400],
	},
	text: {
		primary: '#FFFFFF',
		secondary: '#D8D8D8',
		link: '#CED9E4',
	},
	link: '#23263B',
	icon: '#FFFFFF',
	background: {
		main: '#000000',
		light: '#1E1E1E',
		default: '#000000',
		paper: '#000000',
	},
	common: {
		border: '#838383',
		inputBorder: '#ced4da',
		grey: '#CDCECF',
		input: '#000000',
	},
	divider: colors.grey[200],
}
