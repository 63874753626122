import React from 'react'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { ResponsiveImage } from 'components'
import { resize } from 'lib/cloudinary'
import {
	THUMBNAIL_HEIGHT,
	THUMBNAIL_WIDTH_X3,
} from 'lib/constants'

const TopicItem = ({
	topic,
	width = THUMBNAIL_WIDTH_X3,
	height = THUMBNAIL_HEIGHT,
	handleClick,
	...props
}) => {
	const classes = useStyles()
	const imageUrl = resize(topic.image_url, {
		width: width,
		height: height,
	})

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => handleClick(topic)}
			>
				<ResponsiveImage
					src={imageUrl}
					gradient
					borderRadius
					width={width}
				/>
			</Button>
			<center className={classes.description}>
				<Typography
					variant="body1"
					color="textPrimary"
				>
					{topic.description}
				</Typography>
			</center>
		</div>
	)
}

export default TopicItem

const useStyles = makeStyles((theme) => ({
	root: {},
	description: {
		padding: theme.spacing(2, 1),
	},
	button: {
		borderRadius: 0,
	},
}))
