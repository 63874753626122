import React from "react";
import { useAlerts, useAuth } from "hooks";
import { AuthScreen } from "components";
import SignupForm from "containers/auth/SignupForm";
import { validateUser } from "validations/users";
import moment from "moment";

const Signup = ({ ...props }) => {
  const { user, handleChange, signupUser,authenticateFromToken } = useAuth();
  const { showAlertError } = useAlerts();
  const [promoCodePopupMessage, setPromoCodePopupMessage] = React.useState();
  const [showPromoSuccessPopup, setShowPromoSuccessPopup] =
    React.useState(false);

  React.useEffect(() => {
    setShowPromoSuccessPopup(showPromoSuccessPopup);
  }, [showPromoSuccessPopup]);

  React.useEffect(() => {
    setPromoCodePopupMessage(promoCodePopupMessage);
  }, [promoCodePopupMessage]);
  const handleSubmit = async () => {
    const valid = validateUser(user);
    if (valid.isValid) {
      const resp = await signupUser({
        ...user,
        dob: moment(user.dob),
      });
      if (resp?.id&&resp.promo_code) {
        console.log(resp.promo_code);
        // eslint-disable-next-line max-len
        setPromoCodePopupMessage(
          `${resp.promo_code.promo_rule.user_description}`
        );
        setShowPromoSuccessPopup(true);
        // setCurrentUser(resp)
        console.log(`userdata ${resp}`);
        console.log(`user token ${resp.token}`);
        authenticateFromToken(resp.token);
      } else if (resp?.id){
        authenticateFromToken(resp.token);
        window.location.href = '/home'
      }
    } else {
      valid.messages.map((m) => showAlertError(m));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key == "Enter") handleSubmit();
  };

  return (
    <div>
      <AuthScreen
        title="Sign Up"
        open={showPromoSuccessPopup}
        handleClosePopUp={() =>window.location.href = '/home'}
        alertMessage={promoCodePopupMessage}
      >
        <SignupForm
          user={user}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          handleSubmit={handleSubmit}
        />
      </AuthScreen>
    </div>
  );
};

export default Signup;
