import React from 'react'
import api from 'api'
import {
	useAlerts,
	useLoaders,
	useResource,
} from 'hooks'

const useCourses = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const {
		resourceId: courseId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: course,
		resources: courses,
		findOne: findCourse,
		findMany: findCourses,
		update: updateCourse,
		create: createCourse,
		save: saveCourse,
		destroy: deleteCourse,
		handleChange,
		handleChangePage,
		reload: reloadCourse,
		reloadMany: reloadCourses,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		meta,
		setMeta,
		setResources: setCourses,
	} = useResource({
		id: id,
		url: '/api/v1/courses',
		name: 'course',
	})

	const browseCourses = async (params) => {
		showLoading()
		const resp = await api.get(
			'/api/v1/courses/browse',
			{
				params: params,
			}
		)
		console.log(resp)
		setCourses(resp?.data)
		setMeta(resp?.meta)
		hideLoading()
		return resp
	}

	return {
		courseId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		course,
		courses,
		findCourse,
		findCourses,
		browseCourses,
		saveCourse,
		updateCourse,
		createCourse,
		deleteCourse,
		handleChange,
		handleChangePage,
		meta,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadCourse,
		reloadCourses,
	}
}

export default useCourses
