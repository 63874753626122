import React, { useContext, useEffect } from 'react'
import { AppContext } from 'context'
import { usePaywall } from 'hooks'
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Typography,
	makeStyles,
} from '@material-ui/core'
import {
	Lock,
	FiberManualRecord,
	FiberManualRecordOutlined,
	ArrowForwardIos,
} from '@material-ui/icons'
import { isLessonCompleted } from 'lib/utils'

const LessonItem = ({
	lesson,
	handleClick,
	...props
}) => {
	const { currentUser } = useContext(AppContext)

	const { isPaid } = usePaywall()
	const completed = isLessonCompleted(
		currentUser,
		lesson?.id
	)
	const classes = useStyles({ completed })

	return (
		<ListItem
			button
			className={classes.root}
			onClick={(e) => handleClick(lesson)}
		>
			<ListItemIcon className={classes.listItemIcon}>
				<Typography
					variant="h2"
					className={classes.lessonTitle}
				>
					{lesson.position}
				</Typography>
			</ListItemIcon>
			<ListItemText
				primary={
					<Typography
						variant="h2"
						className={classes.lessonTitle}
					>
						{lesson.title}
					</Typography>
				}
				secondary={
					<Typography
						variant="subtitle1"
						color="secondary"
						className={classes.subtitle}
					>
						{lesson.difficulty}
					</Typography>
				}
			/>
			<ListItemSecondaryAction>
				<div className={classes.secondaryAction}>
					<IconButton
						size="small"
						onClick={(e) => handleClick(lesson)}
					>
						{lesson.premium == true &&
						isPaid == false ? (
							<Lock className={classes.lockIcon} />
						) : (
							<ArrowForwardIos
								className={classes.icon}
							/>
						)}
					</IconButton>
					{completed ? (
						<FiberManualRecord
							className={classes.fiberIcon}
						/>
					) : (
						<FiberManualRecordOutlined
							className={classes.fiberIcon}
						/>
					)}
				</div>
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default LessonItem

const useStyles = makeStyles((theme) => ({
	root: {
		alignItems: 'flex-start',
		margin: theme.spacing(2, 0),
		borderRadius: 10,
		border: `1px solid ${theme.palette.primary.light}`,
		'&:hover': {
			border: `1px solid ${theme.palette.secondary.main}`,
		},
	},
	icon: {
		opacity: (props) =>
			props.completed === true ? 0.5 : 1.0,
		height: 44,
		width: 44,
		[theme.breakpoints.down('sm')]: {
			height: 38,
			width: 38,
		},
	},
	lockIcon: {
		opacity: (props) =>
			props.completed === true ? 0.5 : 1.0,
		height: 36,
		width: 36,
		marginRight: 8,
		[theme.breakpoints.down('sm')]: {
			height: 24,
			width: 24,
		},
	},
	fiberIcon: {
		marginTop: 5,
		marginRight: 16,
		color: (props) =>
			props.completed === true
				? theme.palette.secondary.main
				: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			height: 24,
			width: 24,
		},
	},
	lessonTitle: {
		opacity: (props) =>
			props.completed === true ? 0.5 : 1.0,
		fontSize: 72,
		lineHeight: '0.9em',
		[theme.breakpoints.down('sm')]: {
			fontSize: 40,
		},
	},
	subtitle: {
		opacity: (props) =>
			props.completed === true ? 0.5 : 1.0,
		textTransform: 'uppercase',
		fontSize: 18,
		fontWeight: 500,
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
		},
	},
	listItemIcon: {
		margin: theme.spacing(1, 0, 0, 1),
	},
	secondaryAction: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
}))
