import React, { useState, useEffect } from 'react'
import { useAuth, useUsers, useAlerts } from 'hooks'
import { AuthScreen } from 'components'
import {
	Box,
	Button,
	Typography,
	Link,
	makeStyles,
} from '@material-ui/core'
import {
	AddAPhoto,
	PhotoCamera,
} from '@material-ui/icons'
import { useHistory } from 'react-router'
import { VERIFF_API_KEY } from 'lib/constants'
import Veriff from '@veriff/js-sdk'

const Verification = ({ ...props }) => {
	const classes = useStyles()
	const history = useHistory()

	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const { currentUser, updateMe } = useAuth()
	const { createVerification } = useUsers()

	const handleVeriffClick = async () => {
		let resp = await createVerification(
			currentUser.id
		)
		if (resp.data?.session_url) {
			window.open(resp.data.session_url, '_blank')
		}
	}

	const handleSkipClick = () => {
		history.push(`/users/me`)
	}

	return (
		<div>
			<AuthScreen title="ID VERIFICATION">
				<Typography
					variant="body1"
					className={classes.notification}
				>
					Please upload proof of ID to validate your
					Ellevate profile.
					<br />
					<br />
					Scouts on the app won't be able to see your
					profile until you have validated it.
				</Typography>
				<div id="veriff-root"></div>
				<div className={classes.buttons}>
					<Button
						fullWidth
						onClick={handleVeriffClick}
						variant="contained"
						color="primary"
						className={classes.button}
						endIcon={<AddAPhoto />}
					>
						Upload ID
					</Button>
					<Button
						fullWidth
						className={classes.button}
						onClick={handleSkipClick}
						variant="contained"
						color="primary"
					>
						Skip for Now
					</Button>
				</div>
				<Box my={4} />
				<center>
					<Typography variant="body1">
						Already have an account?
					</Typography>
					<Typography variant="body1">
						<Link color="secondary" href="/login">
							Sign In
						</Link>
					</Typography>
				</center>
			</AuthScreen>
		</div>
	)
}

export default Verification

const useStyles = makeStyles((theme) => ({
	notification: {
		padding: theme.spacing(2, 0),
		textTransform: 'uppercase',
		color: theme.palette.text.primary,
		borderTop: `1px solid ${theme.palette.primary.light}`,
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		whiteSpace: 'pre-line',
	},
	buttons: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	button: {
		maxWidth: 200,
		marginBottom: 5,
	},
}))
