import React, { useState, useEffect } from 'react'
import { Modal, RatingInput } from 'components'
import {
	Button,
	Grid,
	Container,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { FILTER_POSITIONS } from 'lib/constants'
import SearchTags from 'containers/search/SearchTags'
import SearchPlayerTags from 'containers/search/SearchPlayerTags'

const SearchFilterModal = ({
	open,
	filters,
	setFilters,
	handleClose,
	handleFilterChange,
	handleSubmit
}) => {
	const classes = useStyles()

	const handleResetClick = () => {
		setFilters({
			tags: [],
      trainer_ids: [],
			difficulty: null,
		})
	}

	const handleDifficultyChange = (event) => {
		const { name, value } = event.target
		handleFilterChange(name, value)
	}

	const handleTagClick = (tag) => {
		let newTags = []
		if (filters.tags.includes(tag)) {
			newTags = filters.tags.filter((t) => t !== tag)
		} else {
			newTags = [...filters.tags, tag]
		}
		handleFilterChange('tags', newTags)
	}

	const handlePlayerTagClick = (tag) => {
		let newTags = []
		if (filters.trainer_ids.includes(tag)) {
			newTags = filters.trainer_ids.filter(
				(t) => t !== tag
			)
		} else {
			newTags = [...filters.trainer_ids, tag]
		}
		handleFilterChange('trainer_ids', newTags)
	}

	return (
		<Modal
			fullScreen
			maxWidth="md"
			open={open}
			handleClose={handleClose}
		>
			<Container>
				<div className={classes.container}>
					<Typography variant="h3">
						FILTERS
					</Typography>
					<Typography variant="h3">
						POSITION{' '}
						{filters?.tags?.length > 0 && (
							<span>({filters?.tags?.length})</span>
						)}
					</Typography>
					<SearchTags
						tags={FILTER_POSITIONS}
						activeTags={filters?.tags}
						handleClick={handleTagClick}
					/>
					<div className={classes.divider} />
					<Typography variant="h3">
						PLAYER{' '}
						{filters?.trainer_ids?.length > 0 && (
							<span>
								({filters?.trainer_ids?.length})
							</span>
						)}
					</Typography>
					<SearchPlayerTags
						activeTags={filters?.trainer_ids}
						handleClick={handlePlayerTagClick}
					/>
					<div className={classes.divider} />
					<Typography variant="h3">
						DIFFICULTY
					</Typography>
					<RatingInput
						label="LEVEL"
						name="difficulty"
						value={filters?.difficulty}
						handleChange={handleDifficultyChange}
					/>
					<div className={classes.divider} />
					<div className={classes.buttonContainer}>
						<Grid container spacing={1}>
							<Grid item xs={6} sm={6}>
								<Button
									fullWidth
									variant="contained"
									className={classes.resetButton}
									onClick={handleResetClick}
								>
									RESET
								</Button>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Button
									fullWidth
									className={classes.submitButton}
									onClick={handleSubmit}
									variant="contained"
								>
									APPLY FILTERS
								</Button>
							</Grid>
						</Grid>
					</div>
				</div>
			</Container>
		</Modal>
	)
}

export default SearchFilterModal

const useStyles = makeStyles((theme) => ({
	root: {},
	modalContent: {
		padding: theme.spacing(2),
	},
	buttonContainer: {
		marginTop: theme.spacing(2),
	},
	submitButton: {
		border: `1px solid ${theme.palette.primary.main}`,
	},
	resetButton: {
		'&&': {
			color: theme.palette.background.main,
			backgroundColor: '#8C8C8C',
			border: `1px solid ${theme.palette.primary.main}`,
		},
	},
	divider: {
		paddingTop: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
}))
