import React from 'react'
import ProgressBar from 'containers/skills/ProgressBar'
import { SKILLS } from 'lib/constants'

const ProgressBars = ({ points }) => {
	return (
		<div>
			{points &&
				Object.keys(points).map((skill_value, i) => {
					let label = SKILLS.find(
						(s) => s.value == skill_value
					)?.label
					let numPoints = parseInt(
						points[skill_value]
					)

					if (!numPoints || numPoints === 0)
						return null

					return (
						<ProgressBar
							key={i}
							points={numPoints}
							label={label}
							icon={skill_value}
						/>
					)
				})}
		</div>
	)
}

export default ProgressBars
