import React from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { makeStyles } from '@material-ui/core'
import GoogleMapSearch from 'containers/clubs/GoogleMapSearch'
import { GOOGLE_MAPS_API_KEY } from 'lib/constants'
import IMAGE from 'assets/images/clubs-near-you.png'

const ClubContainer = () => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: GOOGLE_MAPS_API_KEY, // Add your API key
	})

	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.titleBar}>
				<img src={IMAGE} className={classes.image} />
			</div>
			{isLoaded && <GoogleMapSearch />}
		</div>
	)
}

export default ClubContainer

const useStyles = makeStyles((theme) => ({
	titleBar: {
		position: 'fixed',
		color: theme.palette.common.white,
		top: 90,
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'center',
		zIndex: theme.zIndex.modal - 1,
		[theme.breakpoints.down('sm')]: {
			top: 60,
			left: 10,
			justifyContent: 'flex-start',
		},
	},
	image: {
		width: '100%',
		maxWidth: 320,
		height: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxWidth: 200,
		},
	},
}))
