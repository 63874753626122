import React from 'react'
import {
	Typography,
	Button,
	makeStyles,
} from '@material-ui/core'
import {
	InfoWindow,
	Marker,
} from '@react-google-maps/api'
import { useHistory } from 'react-router'
import { resize } from 'lib/cloudinary'

const GeoMarker = ({
	marker,
	handleActiveMarker,
	activeMarker,
	setActiveMarker,
	handleClick,
}) => {
	const classes = useStyles()
	const history = useHistory()
	const {
		id,
		name,
		position,
		image,
		address,
		level,
	} = marker

	const handleClubClick = () => {
		history.push(`/clubs/${marker?.id}`)
	}

	return (
		<Marker
			key={id}
			position={position}
			onClick={() => handleActiveMarker(id)}
			icon={
				image
					? { url: resize(image, { width: 32 }) }
					: null
			}
		>
			{activeMarker === id ? (
				<InfoWindow
					onCloseClick={() => setActiveMarker(null)}
				>
					<div className={classes.root}>
						<div className={classes.infoWindow}>
							{image && (
								<div
									className={classes.logoContainer}
								>
									<img
										src={image}
										className={classes.logo}
									/>
								</div>
							)}
							<div className={classes.content}>
								<Typography
									variant="subtitle1"
									className={classes.title}
								>
									{name}
								</Typography>
								<Typography
									variant="body2"
									className={classes.subtitle}
								>
									{address}
								</Typography>
								<Typography
									variant="body2"
									className={classes.level}
								>
									{level}
								</Typography>
							</div>
						</div>
						<Button
							fullWidth
							size="small"
							variant="contained"
							color="secondary"
							className={classes.button}
							onClick={handleClubClick}
						>
							VIEW CLUB PROFILE
						</Button>
					</div>
				</InfoWindow>
			) : null}
		</Marker>
	)
}

export default GeoMarker

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 100,
		width: 400,
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			width: 220,
			minHeight: 140,
		},
	},
	title: {
		fontSize: 20,
		textTransform: 'uppercase',
		color: theme.palette.background.main,
		lineHeight: '1.0em',
		marginBottom: 8,
	},
	subtitle: {
		fontWeight: 600,
		fontSize: 11,
		color: theme.palette.background.main,
	},
	level: {
		fontSize: 11,
		color: theme.palette.background.main,
	},
	logoContainer: {
		padding: theme.spacing(1),
	},
	logo: {
		width: 60,
		height: 'auto',
	},
	button: {
		backgroundColor: '#1AEAA1',
		'&:hover': {
			backgroundColor: '#1AEAA1',
		},
	},
	infoWindow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginBottom: theme.spacing(1),
		width: '100%',
		maxWidth: 400,
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
	search: {
		position: 'fixed',
		bottom: 50,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		zInded: theme.zIndex.modal + 1,
	},
	searchBar: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(0, 2),
		borderRadius: 20,
		width: 400,
		backgroundColor: theme.palette.background.main,
		opacity: 0.8,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: theme.spacing(1),
		},
	},
}))
