import React from 'react'
import {
	Button,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { ButtonLoader, Modal } from 'components'

const CancelSubscriptionModal = ({
	open,
	handleClose,
	handleSubmit,
	isLoading,
	...props
}) => {
	const classes = useStyles()
	return (
		<Modal
			showBorder
			open={open}
			handleClose={handleClose}
			showClose={false}
			title="Cancel Subscription"
			subtitle="Are you sure you want to cancel your ELLEVATE Premium Subscription?"
		>
			<Button
				className={classes.button}
				variant="contained"
				fullWidth
				color="primary"
				onClick={handleSubmit}
				startIcon={isLoading && <ButtonLoader />}
			>
				CANCEL SUBSCRIPTION
			</Button>
            <div className={classes.spacing}/>
			<Button
				variant="contained"
				fullWidth
				color="primary"
				onClick={handleClose}
			>
				CLOSE
			</Button>
		</Modal>
	)
}

export default CancelSubscriptionModal

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(1, 0, 3, 0),
	},
}))
