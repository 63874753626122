import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
	useAlerts,
	useLoaders,
	useVideos,
	useCloudinary,
} from 'hooks'
import {
	Button,
	Grid,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import {
	StickyButton,
	ButtonLoader,
	VideoUploader,
} from 'components'
import VideoForm from 'containers/videos/VideoForm'

const VideoUpload = ({ ...props }) => {
	const history = useHistory()
	const classes = useStyles()

	const { id: courseId } = props.match.params

	const { showAlertSuccess, showAlertError } =
		useAlerts()
	const { isLoading, showLoading, hideLoading } =
		useLoaders()

	const [file, setFile] = useState()

	const [activeTags, setActiveTags] = useState([])

	const { cloudinaryUpload } = useCloudinary()

	const { video, createVideo, handleChange } =
		useVideos({
			video: {
				description: '',
				tags: [],
			},
		})

	const handleTagClick = (tag) => {
		let newTags = []
		if (activeTags.includes(tag)) {
			newTags = activeTags.filter((t) => t !== tag)
		} else {
			newTags = [...activeTags, tag]
		}
		setActiveTags(newTags)
	}

	const handleOnDropFile = async (videoFile) => {
		setFile(videoFile)
	}

	const handleDelete = () => {
		setFile(null)
	}

	const handleSubmit = async () => {
		if (!file) {
			showAlertError(
				'Please select a video file to upload'
			)
			return
		}
		try {
			showLoading()
			let upload = await cloudinaryUpload(
				file,
				'/users/videos'
			)
			let videoUrl = upload?.data?.secure_url
			let uploadVideo = {
				...video,
				tags: activeTags,
				url: videoUrl,
			}
			const newVideo = await createVideo(uploadVideo)
			showAlertSuccess(
				'Video uploaded to your highlights gallery'
			)
			if (courseId) {
				history.push(`/courses/${courseId}`)
			} else {
				history.push(`/users/me`)
			}
			return newVideo
		} catch (e) {
			console.log('Video upload error: ', e)
		} finally {
			hideLoading()
		}
	}

	return (
		<div className={classes.root}>
			<Container className={classes.container}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h3">
							UPLOAD
						</Typography>
					</Grid>
					<Grid item sm={12} md={5}>
						<div className={classes.header}>
							<Typography
								variant="body2"
								color="primary"
								className={classes.subtitle}
							>
								Upload videos to complete Training
								Courses and feature them on your
								ELLEVATE profile.
							</Typography>
							<VideoUploader
								videoUrl={file?.preview}
								handleUpload={handleOnDropFile}
								handleDelete={handleDelete}
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={7}>
						<div className={classes.rightPanel}>
							<VideoForm
								video={video}
								activeTags={activeTags}
								handleTagClick={handleTagClick}
								handleChange={handleChange}
							/>
						</div>
					</Grid>
				</Grid>
			</Container>
			<StickyButton>
				<Button
					className={classes.button}
					fullWidth
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					startIcon={isLoading && <ButtonLoader />}
				>
					{isLoading ? 'UPLOADING' : 'UPLOAD'}
				</Button>
			</StickyButton>
		</div>
	)
}

export default VideoUpload

const useStyles = makeStyles((theme) => ({
	root: {},
	titleBar: {
		width: '100%',
	},
	rightPanel: {
		padding: theme.spacing(0, 2),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	position: {
		textTransform: 'uppercase',
	},
	container: {
		paddingTop: 80,
		overflowY: 'scroll',
		height: '100vh',
		[theme.breakpoints.down('sm')]: {
			paddingTop: 20,
		},
	},
	button: {},
	subtitle: {
		marginBottom: theme.spacing(2),
	},
	divider: {
		marginTop: 20,
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
}))
