import React from 'react'
import { usePaywall } from 'hooks'
import {
	CardActionArea,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { CoverImage } from 'components'

const ArticleCover = ({
	article,
	isLocked = false,
	handleClick,
	...props
}) => {
	const classes = useStyles()
	const { isPaid } = usePaywall()
	return (
		<div>
			<CardActionArea
				onClick={() => handleClick(article)}
			>
				<CoverImage
					mobileImageUrl={article.image_url}
					desktopImageUrl={article.web_image_url}
					maxHeight={500}
					borderRadius
					opacity={isLocked ? 0.5 : 1}
				/>
			</CardActionArea>
			<Typography
				variant="body2"
				color="secondary"
				className={classes.caption}
			>
				{article.category}
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={classes.subtitle}
			>
				{article.title}
			</Typography>
		</div>
	)
}

export default ArticleCover

const useStyles = makeStyles((theme) => ({
	root: {},
	caption: {
		marginTop: theme.spacing(1),
		textTransform: 'uppercase',
	},
	subtitle: {
		fontSize: 17,
	},
}))
