import React, {
	useRef,
	useEffect,
	useState,
	useContext,
} from 'react'
import { useAuth, useUsers } from 'hooks'
import { makeStyles } from '@material-ui/core'
import { Modal } from 'components'
import { AppContext } from 'context'
import { VIDEO_COMPLETED_PCT } from 'lib/constants'
import { useHistory } from 'react-router'
// import ReactJWPlayer from 'react-jw-player'
import ReactPlayer from 'react-player'

const LessonJWPlayerModal = ({
	open,
	src,
	title,
	handleClose,
	lesson,
	...props
}) => {
	const classes = useStyles()
	const { currentUser, fetchMe } = useAuth()

	const history = useHistory()

	const { completeLesson } = useUsers({})
	const [completed, setCompleted] = useState(false)
	const [playlist, setPlaylist] = useState()

	const handleBuildPlaylist = () => {
		let captions = []
		let videoSrc
		// if (lesson.jw_player_id) {
		// 	videoSrc = `https://content.jwplatform.com/videos/${lesson.jw_player_id}.mp4`
		// } else {
			videoSrc = lesson.video_url
		// }

		const { jw_captions } = lesson || {}

		if (
			jw_captions &&
			Object.keys(jw_captions).length > 0
		) {
			Object.keys(jw_captions).forEach((key) => {
				let caption = {
					label: key,
					// kind: 'captions',
					kind: 'subtitles',
					src: captions[key],
				}
				captions.push(caption)
			})
		}

		setPlaylist({
			file: videoSrc,
			tracks: captions,
		})
	}

	const handleOnTimeEvent = async (
		playerProgress
	) => {
		const { duration, currentTime } = playerProgress
		if (
			!completed &&
			currentTime / duration > VIDEO_COMPLETED_PCT
		) {
			setCompleted(true)
			await completeLesson(lesson?.id)
		}
	}

	const handleLessonClose = () => {
		if (!completed) {
			handleClose()
		} else {
			handleLessonComplete()
		}
	}

	const handleLessonComplete = async () => {
		const user = await fetchMe()
		const completedCourse =
			user.completed_course_ids.includes(
				lesson.course_id
			)
		const completedLesson =
			user.completed_lesson_ids.includes(lesson.id)
		const viewedLesson =
			user.viewed_lesson_ids.includes(lesson?.id)

		if (viewedLesson) {
			handleClose()
		} else if (completedCourse) {
			history.push(
				`/courses/${lesson.course_id}/lessons/${lesson.id}/course_complete`
			)
		} else if (completedLesson) {
			history.push(
				`/courses/${lesson.course_id}/lessons/${lesson.id}/complete`
			)
		}
	}

	useEffect(() => {
		if (lesson) {
			handleBuildPlaylist()
		}
	}, [lesson])

	useEffect(() => {
		if (!open) {
			setCompleted(false)
		}
	}, [open])

	return (
		<Modal
			open={open}
			handleClose={handleLessonClose}
			title={lesson?.title}
			fullScreen
		>
			{playlist && (
				<center>
					<ReactPlayer 
					
					playing
					width={"100%"}
					height={"100%"}
					url={playlist.file}
					controls={true}
			
					config={{ file: {
						tracks:playlist.tracks
					  },
					  attributes: {
						controlsList: 'nodownload',
						crossOrigin: "anonymous"
					  },
					}}
					/>
					{/* <ReactJWPlayer
						playerId="12345"
						playlist={playlist}
						isAutoPlay
						onTime={handleOnTimeEvent}
						onOneHundredPercent={handleLessonComplete}
					/> */}
				</center>
			)}
		</Modal>
	)
}

export default LessonJWPlayerModal

const useStyles = makeStyles((theme) => ({
	root: {},
	video: {
		height: 'calc(100vh - 74px)',
		maxWidth: '100%',
	},
}))
