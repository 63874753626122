import React, { useState, useEffect } from 'react'
import { useTrainers } from 'hooks'
import {
	Avatar,
	Button,
	makeStyles,
} from '@material-ui/core'
import { resize } from 'lib/cloudinary'
import clsx from 'clsx'

const SearchPlayerTags = ({
	activeTags,
	handleClick,
}) => {
	const classes = useStyles()

	const [tags, setTags] = useState([])
	const { isLoading, findTrainers } = useTrainers({})

	const handleFindTrainers = async () => {
		const trainers = await findTrainers()
		const trainerTags = trainers.map((trainer) => {
			const trainerTag = {
				label: trainer.name,
				value: trainer.id,
				src: resize(trainer.image_url, {
					width: 100,
					height: 100,
				}),
			}
			return trainerTag
		})
		setTags(trainerTags)
	}

	useEffect(() => {
		handleFindTrainers()
	}, [])

	return (
		<div className={classes.root}>
			{tags?.length > 0 &&
				tags.map((tag, i) => (
					<Button
						key={i}
						variant="outlined"
						onClick={() => handleClick(tag.value)}
						className={clsx(classes.tag, {
							[classes.selected]:
								activeTags?.includes(tag.value),
						})}
						startIcon={
							<Avatar
								className={classes.avatar}
								src={tag.src}
							/>
						}
					>
						{tag.label}
					</Button>
				))}
		</div>
	)
}

export default SearchPlayerTags

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	tag: {
		fontSize: 20,
		borderRadius: 20,
		fontFamily: theme.typography.subtitle1.fontFamily,
		padding: theme.spacing(0, 2),
		border: `1px solid ${theme.palette.primary.main}`,
		margin: theme.spacing(0.5),
		[theme.breakpoints.down('sm')]: {
			fontSize: 15,
		},
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},
	avatar: {
		height: 32,
		width: 32,
		margin: 4,
		marginLeft: -8,
	},
}))
