import React from 'react'
import api from 'api'
import {
	useAlerts,
	useLoaders,
	useResource,
} from 'hooks'
import { validateTopic } from 'validations/topics'

const useTopics = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const {
		resourceId: topicId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: topic,
		resources: topics,
		findOne: findTopic,
		findMany: findTopics,
		update: updateTopic,
		create: createTopic,
		save: saveTopic,
		destroy: deleteTopic,
		handleChange,
		handleChangePage,
		reload: reloadTopic,
		reloadMany: reloadTopics,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
	} = useResource({
		id: id,
		url: '/api/v1/topics',
		name: 'topic',
	})

	return {
		topicId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		topic,
		topics,
		findTopic,
		findTopics,
		saveTopic,
		updateTopic,
		createTopic,
		deleteTopic,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadTopic,
		reloadTopics,
	}
}

export default useTopics
