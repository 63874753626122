import React, { useEffect, useState } from 'react'
import {
	Typography,
	InputBase,
	makeStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const Autosuggest = ({
	value,
	options,
	label,
	name,
	placeholder = 'Select',
	multiselect = false,
	handleChange,
	handleInputChange,
	...props
}) => {
	const classes = useStyles({
		flex: label ? true : false,
	})

	const [selected, setSelected] = useState({
		label: '',
		value: null,
	})

	const handleOnChange = (event, newValue) => {
		setSelected(newValue)
		handleChange({
			target: {
				name: name,
				value: newValue?.value,
			},
		})
	}

	useEffect(() => {
		if (typeof value != 'object') {
			setSelected(
				options.find((o) => o.value == value)
			)
		} else {
			setSelected(value)
		}
	}, [value])

	return (
		<div className={classes.root}>
			{label && (
				<div className={classes.label}>
					<Typography variant="body1">
						{label}
					</Typography>
				</div>
			)}
			<Autocomplete
				multiple={multiselect}
				disableCloseOnSelect={multiselect}
				classes={{
					paper: classes.paper,
					option: classes.option,
					popperDisablePortal:
						classes.popperDisablePortal,
				}}
				value={selected}
				onChange={(event, newValue) => {
					handleOnChange(event, newValue)
				}}
				onInputChange={(event, newInputValue) => {
					handleInputChange &&
						handleInputChange(newInputValue)
				}}
				noOptionsText="No options"
				clearOnBlur
				handleHomeEndKeys
				options={options}
				getOptionLabel={(option) => option?.label}
				getOptionSelected={(option, value) =>
					option?.label == value?.label
				}
				renderInput={(params) => (
					<InputBase
						placeholder={placeholder}
						ref={params.InputProps.ref}
						inputProps={{
							...params.inputProps,
							autoComplete: 'none',
						}}
						className={classes.inputBase}
					/>
				)}
			/>
		</div>
	)
}

export default Autosuggest

const useStyles = makeStyles((theme) => ({
	root: {
		display: (props) => props.flex && 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		borderTop: (props) =>
			props.border == true &&
			`1px solid ${theme.palette.primary.light}`,
	},
	iconSelected: {
		height: 20,
		width: 20,
	},
	label: {
		minWidth: 160,
	},
	inputBase: {
		height: 44,
		maxWidth: 160,
		'& input': {
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			padding: theme.spacing(1, 1.5, 1, 1.5),
			borderRadius: 0,
			backgroundColor: 'transparent',
			transition: theme.transitions.create([
				'border-color',
				'box-shadow',
			]),
			fontSize: 15,
			'&:focus': {
				borderColor: theme.palette.primary.main,
			},
		},
	},
	paper: {
		backgroundColor: theme.palette.background.main,
		marginTop: theme.spacing(1),
		color: theme.palette.primary.main,
		fontSize: 15,
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		padding: theme.spacing(1, 1.5),
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent',
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	popperDisablePortal: {
		position: 'relative',
	},
	label: {
		width: 160,
		paddingLeft: theme.spacing(1.5),
	},
	text: {
		flexGrow: 1,
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18,
	},
}))
