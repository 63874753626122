import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import {
	CircularProgress,
	makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import Lottie from 'react-lottie'
import { Center } from 'components'

const LottieAnimation = ({
	src,
	className,
	...rest
}) => {
	const classes = useStyles()

	const [data, setData] = useState()
	const [loading, setLoading] = useState(true)

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: data,
		rendererSettings: {
			filterSize: {
				width: '200%',
				height: '200%',
				x: '-50%',
				y: '-50%',
			},
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	const fetchLottie = async () => {
		setLoading(true)
		let resp = await axios.get(src)
		setData(resp.data)
		setLoading(false)
	}

	useEffect(() => {
		if (src) fetchLottie(src)
	}, [src])

	return (
		<div className={clsx(className, classes.root)}>
			{loading ? (
				<Box p={20} width="100%">
					<Center>
						<CircularProgress
							className={classes.progress}
						/>
					</Center>
				</Box>
			) : (
				<Lottie
					options={defaultOptions}
					height={'auto'}
					width={'100%'}
					isStopped={false}
					isPaused={false}
				/>
			)}
		</div>
	)
}

LottieAnimation.propTypes = {
	src: PropTypes.string.isRequired,
	className: PropTypes.string,
}

export default LottieAnimation

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 'auto',
	},
	Animation: {
		width: '100%',
		height: 'auto',
	},
	progress: {
		opacity: 0.25,
	},
}))
