import React from 'react'
import {
	Hidden,
	Tab,
	Tabs,
	makeStyles,
} from '@material-ui/core'

const UserTabs = ({ activeTab, handleChange }) => {
	const classes = useStyles()

	return (
		<Hidden smUp>
			<Tabs
				value={activeTab}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="fullWidth"
				className={classes.tabs}
			>
				<Tab
					label="HIGHLIGHTS"
					classes={{ root: classes.tab }}
					value={0}
				/>
				<Tab
					label="SKILLS"
					classes={{ root: classes.tab }}
					value={1}
				/>
				<Tab
					label="BIO"
					classes={{ root: classes.tab }}
					value={2}
				/>
			</Tabs>
		</Hidden>
	)
}

export default UserTabs

const useStyles = makeStyles((theme) => ({
	root: {},
	tab: {
		color: theme.palette.primary.light,
		fontFamily: theme.typography.h1.fontFamily,
		fontSize: theme.typography.h4.fontSize,
	},
	tabs: {
		borderBottom: '1px solid #808080',
		'& .Mui-selected': {
			color: theme.palette.primary.main,
		},
	},
}))
