import React, { useState } from 'react'
import {
	FormControl,
	InputBase,
	makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'

const SearchInput = ({
	className,
	name,
	value,
	handleChange,
	placeholder,
	onKeyPress,
	...props
}) => {
	const classes = useStyles()

	return (
		<FormControl
			fullWidth
			className={clsx(className, classes.root)}
		>
			<InputBase
				className={classes.inputBase}
				autoComplete="off"
				fullWidth
				name={name}
				placeholder={placeholder}
				onKeyPress={onKeyPress}
				onChange={handleChange}
				value={value}
			/>
		</FormControl>
	)
}

export default SearchInput

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
	},
	inputBase: {
		height: 44,
		width: '100%',
		'& input, & textarea': {
			color: theme.palette.common.white,
			webkitAppearance: 'none',
			MozAppearance: 'none',
			appearance: 'none',
			padding: theme.spacing(0, 0),
			minHeight: 28,
			fontSize: 15,
		},
	},
}))
