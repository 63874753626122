import React from 'react'
import {
	Box,
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { Modal } from 'components'

const CourseLeaveModal = ({
	open,
	handleClose,
	handleClick,
}) => {
	const classes = useStyles()

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title="Leave Course"
			subtitle="Are you sure you want to lave this Training course?"
			border
		>
			<Box>
				<Button
					fullWidth
					className={classes.button}
					color="primary"
					variant="contained"
					onClick={handleClick}
				>
					Leave Course
				</Button>

				<Button
					fullWidth
					className={classes.button}
					color="primary"
					variant="contained"
					onClick={handleClose}
				>
					Cancel
				</Button>
			</Box>
		</Modal>
	)
}

export default CourseLeaveModal

const useStyles = makeStyles((theme) => ({
	root: {},
	button: {
		margin: theme.spacing(1, 0, 1, 0),
	},
}))
