import React, { useEffect } from 'react'
import {
	usePaywall,
	useAuth,
	useEvents,
	useResponsive,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	StickyButton,
	Placeholder,
	PageHeader,
} from 'components'
import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
} from '@material-ui/core'
import EventCover from 'containers/events/EventCover'
import EventItem from 'containers/events/EventItem'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'
import {
	Lock,
	ScheduleOutlined,
} from '@material-ui/icons'
import Pagination from '@material-ui/lab/Pagination'

const EventContainer = ({ ...props }) => {
	const history = useHistory()
	const classes = useStyles()

	const {
		isPaid,
		showPaywall,
		hidePaywall,
		openPaywall,
	} = usePaywall()

	const {
		events,
		findEvents,
		paginate,
		page,
		numPages,
	} = useEvents({})

	const { isMobile } = useResponsive()
	const handleEventClick = (event) => {
		if (!isPaid) {
			showPaywall()
		} else {
			history.push(`/events/${event.id}`)
		}
	}

	useEffect(() => {
		findEvents({})
	}, [])

	const isLocked = !isPaid

	return (
		<div>
			<Container maxWidth="lg">
				<PageHeader
					title="Events"
					subtitle="Exclusive events for our Premium members to sign up to and attend."
				/>
				{events?.length > 0 ? (
					<div>
						{!isMobile ? (
							<EventCover
								event={events[0]}
								handleClick={handleEventClick}
								isLocked={isLocked}
							/>
						) : (
							<EventItem
								event={events[0]}
								handleClick={handleEventClick}
								isLocked={isLocked}
							/>
						)}
						<div className={classes.eventList}>
							<Grid container spacing={1}>
								{events &&
									events.map((event, i) =>
										i == 0 && page == 1 ? null : (
											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												lg={4}
												key={event.id}
											>
												<EventItem
													event={event}
													handleClick={
														handleEventClick
													}
													isLocked={isLocked}
												/>
											</Grid>
										)
									)}
							</Grid>
						</div>
					</div>
				) : (
					<Placeholder
						icon={<ScheduleOutlined />}
						title="No Events."
						subtitle="There are currently no upcoming events."
					/>
				)}

				<Box my={4}>
					<Pagination
						onChange={(ev, page) => paginate(page)}
						color="primary"
						size="small"
						shape="rounded"
						page={page}
						count={numPages}
					/>
				</Box>
			</Container>
			<div className={classes.spacer} />
			<PremiumSignupModal
				open={openPaywall}
				handleClose={hidePaywall}
			/>
			{isLocked && (
				<StickyButton>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={showPaywall}
						startIcon={<Lock />}
					>
						UPGRADE NOW FOR ACCESS
					</Button>
				</StickyButton>
			)}
		</div>
	)
}

export default EventContainer

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {},
	caption: {
		textAlign: 'center',
	},
	button: {
		padding: 0,
		borderRadius: 0,
	},
	spacer: {
		height: 100,
	},
}))
