const moment = require('moment')

export const isDate = (value) => {
	if (!value) {
		return false
	}
	let date = moment(value)
	return date.isValid()
}

export const isEmail = (value) => {
	if (!value) {
		return false
	}
	let regEx = /\S+@\S+\.\S+/
	if (value && !regEx.test(value)) {
		return false
	} else {
		return true
	}
}

export const isUrl = (string) => {
	try {
		new URL(string)
		return true
	} catch (e) {
		return false
	}
}

export const notEmpty = (value) => {
	return !isEmpty(value)
}

export const isEmpty = (value) => {
	if (
		value === null ||
		value === undefined ||
		value === '' ||
		value.length === 0
	) {
		return true
	} else {
		return false
	}
}

export const isFalse = (value) => {
	if (!value) {
		return false
	}
	return value === false ? true : false
}

export const isMinLength = (value, length) => {
	if (!value) {
		return false
	}
	if (value && value.length < length) {
		return false
	} else {
		return true
	}
}
