import React from 'react'
import { useAuth } from 'hooks'
import { AuthScreen } from 'components'
import LoginForm from 'containers/auth/LoginForm'
import { useEffect } from 'react'

const Login = ({ ...props }) => {
	const { user, handleChange, loginUser , useQueryParams} = useAuth();
	const { token } = useQueryParams();



	const handleSubmit = async () => {
		await loginUser(user)
	}

	const handleKeyPress = (event) => {
		if (event.key == 'Enter') handleSubmit()
	}

	return (
		<div>
			<AuthScreen title="Sign In" open ={false} alertMessage="" handleClosePopUp={null}>
				<LoginForm
					user={user}
					handleChange={handleChange}
					handleKeyPress={handleKeyPress}
					handleSubmit={handleSubmit}
				/>
			</AuthScreen>
		</div>
	)
}

export default Login
