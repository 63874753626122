import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import {
	useAlerts,
	usePaywall,
	useCourses,
	useUsers,
	useVideo,
} from 'hooks'
import {
	Box,
	Button,
	Container,
} from '@material-ui/core'
import {
	StickyButton,
	ButtonLoader,
	VideoCover,
	VideoModal,
} from 'components'
import CourseDetails from 'containers/courses/CourseDetails'
import { AppContext } from 'context'
import { Lock } from '@material-ui/icons'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'

const CourseShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const { currentUser } = useContext(AppContext)
	const {
		isPaid,
		showPaywall,
		hidePaywall,
		openPaywall,
	} = usePaywall()

	const {
		activeVideo,
		videoOpen,
		playVideo,
		stopVideo,
	} = useVideo()

	const { showAlertSuccess } = useAlerts()
	const { isLoading, enrollCourse } = useUsers()

	const { course, findCourse } = useCourses({
		id: id,
	})

	const handleEnrollClick = async () => {
		await enrollCourse(course?.id)
		showAlertSuccess('Enrolled in course')
		history.push(`/courses/${course.id}`)
	}

	useEffect(() => {
		if (id) {
			findCourse(id)
		}
	}, [])

	useEffect(() => {
		if (currentUser?.course?.id === course?.id) {
			history.push(`/courses/${course.id}`)
		}
	}, [currentUser, course])

	return (
		<>
			{course?.image_url && (
				<VideoCover
					mobileImageUrl={course?.image_url}
					desktopImageUrl={course?.web_image_url}
					maxHeight={400}
					handleClick={() => playVideo(course)}
				/>
			)}
			<Container>
				<div>
					<Box p={2}>
						<CourseDetails course={course} />
					</Box>
				</div>
				<VideoModal
					autoPlay
					title={activeVideo?.title}
					open={videoOpen}
					src={activeVideo?.src}
					captions={activeVideo?.captions}
					videoId={activeVideo?.videoId}
					handleClose={stopVideo}
				/>
			</Container>
			<StickyButton>
				{course?.premium && !isPaid ? (
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={showPaywall}
						startIcon={isLoading && <Lock />}
					>
						Upgrade Now for Access
					</Button>
				) : (
					<Button
						fullWidth
						variant="contained"
						color="secondary"
						onClick={handleEnrollClick}
						startIcon={isLoading && <ButtonLoader />}
					>
						Enroll
					</Button>
				)}
			</StickyButton>
			<PremiumSignupModal
				open={openPaywall}
				handleClose={hidePaywall}
			/>
		</>
	)
}

export default CourseShow
