import React, { useContext } from 'react'
import { AppContext } from 'context'
import { useHistory } from 'react-router'

const useLessonComplete = ({ lesson }) => {
	const { currentUser } = useContext(AppContext)
	const history = useHistory()

	const checkLessonComplete = async () => {
		const {
			viewed_lesson_ids,
			completed_lesson_ids,
		} = currentUser
		if (
			completed_lesson_ids.includes(lesson?.id) &&
			!viewed_lesson_ids.includes(lesson?.id)
		) {
			navigateLessonComplete()
		}
	}

	const navigateLessonComplete = async () => {
		history.push(
			`/courses/${lesson.course_id}/lessons/${lesson.id}/complete`
		)
	}

	const navigateCourseComplete = async () => {
		history.push(
			`/courses/${lesson.course_id}/complete`
		)
	}

	return {
		checkLessonComplete,
		navigateLessonComplete,
	}
}

export default useLessonComplete
