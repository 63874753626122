import React from 'react'
import clsx from 'clsx'
import {
	CircularProgress,
	makeStyles,
} from '@material-ui/core'

function ButtonLoader() {
	const classes = useStyles()

	return (
		<CircularProgress
			size={20}
			className={classes.loader}
		/>
	)
}

export default ButtonLoader

const useStyles = makeStyles((theme) => ({
	loader: {
		color: theme.palette.background.main,
	},
}))
