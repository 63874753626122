import React from 'react'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { ResponsiveImage } from 'components'
import { resize } from 'lib/cloudinary'
import { THUMBNAIL_WIDTH_X4 } from 'lib/constants'

const TopicItem = ({
	topic,
	width = THUMBNAIL_WIDTH_X4,
	handleClick,
	...props
}) => {
	const classes = useStyles()
	const imageUrl = resize(topic.thumbnail_url, {
		width: width,
	})

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => handleClick(topic)}
			>
				<ResponsiveImage
					width={width}
					src={imageUrl}
					gradient
					borderRadius
				/>
			</Button>
		</div>
	)
}

export default TopicItem

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {},
	caption: {},
	button: {
		padding: 0,
		borderRadius: 0,
	},
}))
