import React, { useState, useEffect } from 'react'
import { useAlerts, useBenefits } from 'hooks'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { Modal } from 'components'
import ResponsiveImage from 'components/Image'
import { FileCopy } from '@material-ui/icons'
import copy from 'copy-to-clipboard'

const PromotionModal = ({
	open,
	handleClose,
	benefit,
}) => {
	const classes = useStyles()

	const [loading, setLoading] = useState(true)
	const [promotion, setPromotion] = useState(false)

	const { showAlertSuccess } = useAlerts()

	const { redeemCode } = useBenefits({
		id: benefit?.id,
	})

	const handleCopyClick = () => {
		copy(promotion.code)
		showAlertSuccess('Promotion copied to clipboard')
	}

	const handleVisitClick = () => {
		copy(promotion.code)
		showAlertSuccess('Promotion copied to clipboard')
		window.open(benefit.url, '_blank')
	}

	const handleRedeemCode = async () => {
		let resp = await redeemCode(benefit?.id)
		setLoading(false)
		if (resp?.id) {
			setPromotion(resp)
		}
	}

	useEffect(() => {
		if (benefit?.id && open) {
			handleRedeemCode()
		}
	}, [benefit?.id, open])

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			showClose={true}
			showBorder
		>
			<div className={classes.root}>
				<center>
					<img
						className={classes.image}
						src={benefit.thumbnail_url}
					/>
					<Typography variant="body1">
						Enter this code in the promotional code
						area during checkout in the order to avail
						benefit.
					</Typography>
					<Typography
						variant="h2"
						className={classes.promoContainer}
					>
						<span className={classes.promoOutline}>
							{promotion.code}
						</span>
					</Typography>
				</center>
				<Button
					fullWidth
					className={classes.buttonSecondary}
					color="primary"
					variant="outlined"
					startIcon={<FileCopy />}
					onClick={handleCopyClick}
				>
					Copy Code
				</Button>

				<Button
					fullWidth
					className={classes.button}
					color="primary"
					variant="contained"
					onClick={handleVisitClick}
				>
					Visit Site
				</Button>
			</div>
		</Modal>
	)
}

export default PromotionModal

const useStyles = makeStyles((theme) => ({
	root: {},
	image: {
		width: '100%',
		height: 'auto',
		maxWidth: 360,
	},
	button: {
		margin: theme.spacing(1, 0, 1, 0),
	},
	buttonSecondary: {
		borderWidth: 2,
		margin: theme.spacing(1, 0, 1, 0),
	},
	promoContainer: {
		margin: theme.spacing(3),
	},
	promoOutline: {
		padding: theme.spacing(0, 2),
		border: `2px dashed${theme.palette.common.white}`,
		borderRadius: 4,
	},
}))
