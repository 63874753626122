import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { TextInput } from "components";
import { useAlerts, usePaywall } from "hooks";

import mobile from "is-mobile";



function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    alignSelf:"center",
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    alignItems: "center",
    justifyContent: "center",
    width:550,
    border: `1px solid ${theme.palette.secondary.main}`,
    outline: 0,
  },
  circleView: {
    width: 100,
    height: 100,
    borderRadius: 100,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderWidth: 2,
    padding: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    height: 80,
    width: 80,
    alignSelf: "center",
  },
}));
export default function PromotionCodeModal({ open = false, handleClose }) {
  const [promotionValue, setPromotionValue] = useState("");
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {addPromoCode } = usePaywall();


  const handleSubmit = async () => {
    if(promotionValue.length>1){
        const resp = await addPromoCode(promotionValue); 
    }
 };

  const handleKeyPress = (event) => {
    if (event.key == "Enter") handleSubmit();
  };

  const handleChange = (ev) => {
    const { name } = ev.target;
    let value = ev.target.value;
    setPromotionValue({
      ...promotionValue,
      [name]: value,
    });
    console.log(promotionValue);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box display="flex" justifyContent="center">
              <Typography marginBottom={10} variant={"h3"}>
                PROMOTION CODE
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              marginTop={5}
              marginLeft={2}
              marginRight={2}
            >
              <TextInput
                required={true}
                name="value"
                value={promotionValue.value}
                onKeyPress={handleKeyPress}
                placeholder="INPUT YOUR PROMO CODE HERE"
                handleChange={handleChange}
              />
            </Box>
            <center>
              <Box my={6}>
                <Button
                 fullWidth
                  className={classes.button}
                  color="primary"
                  onClick={handleSubmit}
                  variant="contained"
                >
                  Apply
                </Button>
              </Box>
            </center>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
