import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { GoogleMap } from '@react-google-maps/api'
import Marker from 'containers/clubs/Marker'

const Map = ({
	center,
	zoom = 8,
	markers,
	activeMarker,
	setActiveMarker,
	handleActiveMarker,
	googleMap,
	setGoogleMap,
	disableFitBounds = false,
}) => {
	const classes = useStyles()

	const mapStyles = {
		width: '100vw',
		height: 'calc(100vh - 48px)',
	}

	// Resize google map to fit markers
	const handleFitBounds = (markers) => {
		if (markers?.length > 0) {
			const bounds =
				new window.google.maps.LatLngBounds()
			markers.forEach(({ position }) =>
				bounds.extend(position)
			)
			if (googleMap) {
				googleMap.fitBounds(bounds)
			}
		}
	}

	const handleOnLoad = (map) => {
		const bounds =
			new window.google.maps.LatLngBounds()
		markers.forEach(({ position }) =>
			bounds.extend(position)
		)
		map.fitBounds(bounds)
		setGoogleMap(map)
	}

	useEffect(() => {
		if (markers?.length > 0 && !disableFitBounds) {
			handleFitBounds(markers)
		}
	}, [markers])

	return (
		<div className={classes.root}>
			<GoogleMap
				onLoad={handleOnLoad}
				onClick={() => setActiveMarker(null)}
				mapContainerStyle={mapStyles}
				defaultCenter={center}
				zoom={zoom}
				options={{
					disableDefaultUI: true,
				}}
			>
				{markers &&
					markers.map((marker) => (
						<Marker
							key={marker.id}
							marker={marker}
							activeMarker={activeMarker}
							setActiveMarker={setActiveMarker}
							handleActiveMarker={handleActiveMarker}
						/>
					))}
			</GoogleMap>
		</div>
	)
}

export default Map

const useStyles = makeStyles((theme) => ({
	root: {},
}))
