import ProximanovaRegular from 'assets/fonts/proximanova-regular.ttf'
import ProximanovaMedium from 'assets/fonts/proximanova-medium.ttf'
import ProximanovaSemibold from 'assets/fonts/proximanova-semibold.ttf'
import TungstenBold from 'assets/fonts/Tungsten-Bold.otf'
import TungstenSemibold from 'assets/fonts/Tungsten-Semibold.otf'

// Fonts are registered in MuiCssBaseline overrides and
// in typography

export const proximanovaMedium = {
	fontFamily: 'Proximanova Medium',
	fontStyle: 'semibold',
	fontWeight: 500,
	src: `url(${ProximanovaMedium})`,
}

export const proximanovaRegular = {
	fontFamily: 'Proximanova Regular',
	fontStyle: 'normal',
	fontWeight: 400,
	src: `url(${ProximanovaRegular})`,
}

export const proximanovaSemibold = {
	fontFamily: 'Proximanova Semibold',
	fontStyle: 'normal',
	fontWeight: 600,
	src: `url(${ProximanovaSemibold})`,
}

export const tungstenBold = {
	fontFamily: 'Tungsten Bold',
	fontStyle: 'bold',
	fontWeight: 700,
	src: `url(${TungstenBold})`,
}

export const tungstenSemibold = {
	fontFamily: 'Tungsten Semibold',
	fontStyle: 'semibold',
	fontWeight: 600,
	src: `url(${TungstenSemibold})`,
}
