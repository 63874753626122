import React from 'react'
import {
	Button,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { ResponsiveImage } from 'components'
import CourseMeta from 'containers/courses/CourseMeta'
import { resize } from 'lib/cloudinary'
import {
	COURSE_THUMBNAIL_HEIGHT,
	COURSE_THUMBNAIL_WIDTH_X3,
} from 'lib/constants'

const CourseItem = ({
	course,
	handleClick,
	...props
}) => {
	const classes = useStyles()

	const imageUrl = resize(course.thumbnail_url, {
		width: COURSE_THUMBNAIL_WIDTH_X3,
		height: COURSE_THUMBNAIL_HEIGHT,
	})

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => handleClick(course)}
			>
				<ResponsiveImage
					src={imageUrl}
					height={COURSE_THUMBNAIL_HEIGHT}
					width={COURSE_THUMBNAIL_WIDTH_X3}
					borderRadius
				/>
			</Button>
			<Typography
				variant="h4"
				color="textPrimary"
				className={classes.title}
			>
				{course.title}
			</Typography>
			<Typography
				variant="body2"
				color="secondary"
				className={classes.subtitle}
			>
				{course.subtitle}
			</Typography>
			<CourseMeta course={course} />
		</div>
	)
}

export default CourseItem

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
	},
	title: {
		lineHeight: '1em',
	},
	subtitle: {},
	button: {
		padding: 0,
		borderRadius: 0,
	},
}))
