import React from 'react'
import { makeStyles } from '@material-ui/core'

const StickyButton = ({ children }) => {
	const classes = useStyles()
	return (
		<div>
			<div className={classes.spacer}></div>
			<div className={classes.sticky}>
				<div className={classes.container}>
					{children}
				</div>
			</div>
		</div>
	)
}

export default StickyButton

const useStyles = makeStyles((theme) => ({
	spacer: {
		height: '100px',
		width: '100%',
	},
	sticky: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: 74,
		position: 'fixed',
		bottom: 0,
		width: '100%',
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.main,
	},
	container: {
		width: 400,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
}))
