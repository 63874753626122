import React from 'react'
import { AsyncAutosuggest } from 'components'

const ClubAutosuggest = ({
	value,
	label,
	handleChange,
}) => (
	<AsyncAutosuggest
		name="club_id"
		label={label}
		value={value}
		displayField="name"
		remoteUrl="/api/v1/clubs"
		placeholder="Select"
		handleChange={handleChange}
	/>
)

export default ClubAutosuggest
