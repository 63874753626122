import React, { useContext, useState } from 'react'
import { AppContext } from 'context'
import { useAuth } from 'hooks'
import {
	AppBar,
	Box,
	Hidden,
	Breadcrumbs,
	Button,
	Grid,
	Link,
	Toolbar,
	Typography,
	IconButton,
	makeStyles,
} from '@material-ui/core'
import {
	Close,
	Menu,
	NavigateNext,
} from '@material-ui/icons'
import { Breadcrumb, Drawer } from 'components'
import Logo from 'assets/logo.svg'
import { useHistory } from 'react-router'

const Header = (props) => {
	const classes = useStyles()
	const history = useHistory()

	const [open, setOpen] = useState(false)
	const toggleMenu = () => setOpen(!open)
	const { currentUser } = useContext(AppContext)

	const handleLogoClick = () => {
		if (currentUser?.id) {
			history.push('/home')
		} else {
			history.push('/login')
		}
	}

	return (
		<div>
			<AppBar
				className={classes.appBar}
				position="fixed"
				elevation={0}
			>
				<Toolbar
					variant="dense"
					className={classes.toolbar}
				>
					<div className={classes.menuLeft}>
						<div className={classes.breadWrapper}>
							{currentUser?.id && <Breadcrumb />}
						</div>
					</div>
					<div className={classes.menuCenter}>
						<Button onClick={handleLogoClick}>
							<img
								src={Logo}
								className={classes.logo}
							/>
						</Button>
					</div>
					<div className={classes.menuRight}>
						{currentUser?.id && (
							<Box className={classes.box}>
								<IconButton
									onClick={toggleMenu}
									color="primary"
								>
									{open ? <Close /> : <Menu />}
								</IconButton>
							</Box>
						)}
					</div>
				</Toolbar>
			</AppBar>
			<Drawer open={open} toggleMenu={toggleMenu} />
		</div>
	)
}

export default Header

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: theme.palette.background.main,
		borderBottom: `1px solid ${theme.palette.common.border}`,
		overflow: 'auto',
	},
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	menuRight: {
		width: 100,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	menuLeft: {
		width: 100,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	box: {
		display: 'flex',
		flexDirection: 'row',
	},
	logo: {
		//	marginTop: 10,
	},
	breadWrapper: {
		position: 'absolute',
		top: '12px',
	},
}))
