import React, { useEffect } from 'react'
import { useCourses } from 'hooks'
import { useHistory } from 'react-router-dom'
import CourseCarousel from 'containers/courses/CourseCarousel'
import { CATEGORIES } from 'lib/constants'

const CourseBrowse = ({ filters = {}, ...props }) => {
	const history = useHistory()

	const { tags, difficulty, trainer_ids } = filters

	const { isLoading, courses, meta, browseCourses } =
		useCourses({})

	const handleCourseClick = (course) => {
		history.push(`/courses/${course.id}/enroll`)
	}

	useEffect(() => {
		browseCourses({
			'courses.categories': tags,
			'courses.trainer_ids': trainer_ids,
			'courses.difficulty': difficulty,
		})
	}, [tags, difficulty, trainer_ids])

	return (
		<div>
			{Object.keys(courses)?.length > 0 &&
				Object.keys(courses).map((category, i) => {
					const trainingCourses = courses[category]
					let label = CATEGORIES.find(
						(c) => c.value === category
					)?.label
					if (meta[category]) {
						label += ` (${meta[category]})`
					}
					if (trainingCourses.length === 0)
						return null
					return (
						<CourseCarousel
							key={`${label}_${trainingCourses[0].id}`}
							label={label}
							courses={trainingCourses}
							handleClick={handleCourseClick}
						/>
					)
				})}
		</div>
	)
}

export default CourseBrowse
