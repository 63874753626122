import React from 'react'
import { makeStyles } from '@material-ui/core'

//Skill icons
import ControlIcon from 'assets/icons/control.svg'
import DribblingIcon from 'assets/icons/dribbling.svg'
import DefendingIcon from 'assets/icons/defending.svg'
import IntelligenceIcon from 'assets/icons/intelligence.svg'
import PassingIcon from 'assets/icons/passing.svg'
import ShootingIcon from 'assets/icons/shooting.svg'
import StarIcon from 'assets/icons/star.svg'

//What you need icons
import OnePlayerIcon from 'assets/icons/1_player.svg'
import TwoPlayersIcon from 'assets/icons/2_players.svg'
import ThreePlayersIcon from 'assets/icons/3_players.svg'
import FourPlayersIcon from 'assets/icons/4_players.svg'
import FivePlayersIcon from 'assets/icons/5_players.svg'
import BallIcon from 'assets/icons/ball.svg'
import ConeIcon from 'assets/icons/cone.svg'
import GoalIcon from 'assets/icons/goal.svg'
import MannequinIcon from 'assets/icons/mannequin.svg'
import PhoneIcon from 'assets/icons/phone.svg'
import PolesIcon from 'assets/icons/poles.svg'

const ReactIcon = ({ icon, size = 40, ...rest }) => {
	const classes = useStyles({ size })

	let Icon = ''
	switch (icon) {
		case '1_player':
			Icon = OnePlayerIcon
			break
		case '2_players':
			Icon = TwoPlayersIcon
			break
		case '3_players':
			Icon = ThreePlayersIcon
			break
		case '4_players':
			Icon = FourPlayersIcon
			break
		case '5_players':
			Icon = FivePlayersIcon
			break
		case 'ball':
		case 'football':
			Icon = BallIcon
			break
		case 'phone':
			Icon = PhoneIcon
			break
		case 'cone':
			Icon = ConeIcon
			break
		case 'control':
			Icon = ControlIcon
			break
		case 'dribbling':
			Icon = DribblingIcon
			break
		case 'defending':
			Icon = DefendingIcon
			break
		case 'goal':
			Icon = GoalIcon
			break
		case 'mannequin':
			Icon = MannequinIcon
			break
		case 'passing':
			Icon = PassingIcon
			break
		case 'phone':
			Icon = PhoneIcon
			break
		case 'poles':
			Icon = PolesIcon
			break
		case 'smart':
			Icon = IntelligenceIcon
			break
		case 'shooting':
			Icon = ShootingIcon
			break
		case 'star':
			Icon = StarIcon
			break
		default:
			Icon = StarIcon
			break
	}

	return <img src={Icon} className={classes.icon} />
}

export default ReactIcon

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.common.white,
		height: (props) => props.size,
		width: (props) => props.size,
	},
}))
