import palette from '../palette'

export default {
	root: {
		borderRadius: 0,
	},
	input: {
		borderRadius: 0,
		'&::placeholder': {
			opacity: 1,
			color: palette.text.secondary,
		},
	},
}
