import React from 'react'
import api from 'api'
import {
	useAlerts,
	useLoaders,
	useResource,
} from 'hooks'

const useLessons = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()

	const {
		resourceId: lessonId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: lesson,
		resources: lessons,
		findOne: findLesson,
		findMany: findLessons,
		update: updateLesson,
		create: createLesson,
		save: saveLesson,
		destroy: deleteLesson,
		handleChange,
		handleChangePage,
		reloadOne: reloadLesson,
		reloadMany: reloadLessons,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
	} = useResource({
		id: id,
		url: '/api/v1/lessons',
		name: 'lesson',
	})

	const findLevelProgress = async (lessonId) => {
		showLoading()
		const resp = await api.get(
			`/api/v1/lessons/${lessonId}/level_ups`
		)
		hideLoading()
		return resp?.data
	}

	return {
		lessonId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		lesson,
		lessons,
		findLesson,
		findLessons,
		saveLesson,
		updateLesson,
		createLesson,
		deleteLesson,
		findLevelProgress,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadLesson,
		reloadLessons,
	}
}

export default useLessons
