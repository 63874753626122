import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { AppContext } from 'context'
import { useHistory } from 'react-router'
import { useLessons, useCourses } from 'hooks'
import {
	Box,
	Button,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { StickyButton, CoverImage } from 'components'

const CourseComplete = ({ ...props }) => {
	const history = useHistory()
	const { id: lessonId } = props.match.params

	const classes = useStyles()

	const { lesson, findLesson } = useLessons({
		id: lessonId,
	})

	const handleClick = () => {
		history.push(
			`/courses/${lesson.course_id}/lessons/${lessonId}/progress`
		)
	}

	useEffect(() => {
		if (lessonId) {
			findLesson(lessonId)
		}
	}, [lessonId])

	console.log(lesson)

	return (
		<div>
			{lesson?.course && (
				<CoverImage
					mobileImageUrl={lesson?.course?.image2_url}
					desktopImageUrl={
						lesson?.course?.web_image2_url
					}
				/>
			)}
			<Container maxWidth={'xl'}>
				<Box p={2}>
					<center>
						<Typography
							variant="subtitle1"
							color="secondary"
							className={classes.subtitle}
						>
							{lesson?.course?.title}
						</Typography>
						<Typography
							variant="h1"
							color="textPrimary"
							className={classes.title}
						>
							TRAINING COURSE COMPLETE
						</Typography>
						<Typography
							variant="subtitle1"
							color="secondary"
							className={classes.subtitle}
						>
							CONGRATULATIONS
						</Typography>
						<Typography
							variant="body2"
							color="primary"
							className={classes.body}
						>
							Well done for completing session 1 of
							this special edition training course,
							with {lesson?.course?.trainer?.name}.
						</Typography>
					</center>
				</Box>
			</Container>
			<StickyButton>
				<Button
					fullWidth
					onClick={handleClick}
					variant="contained"
					color="primary"
				>
					NEXT
				</Button>
			</StickyButton>
		</div>
	)
}

export default CourseComplete

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {
		fontSize: 180,
		lineHeight: 0.8,
		[theme.breakpoints.down('xs')]: {
			fontSize: 64,
		},
	},
	subtitle: {
		textTransform: 'uppercase',
	},
	body: {
		marginTop: theme.spacing(2),
		fontSize: 20,
		fontWeight: 500,
	},
	button: {
		marginTop: theme.spacing(2),
		maxWidth: 320,
	},
}))
