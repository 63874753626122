import React, { useEffect } from 'react'
import { useTopics, useAlerts } from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	Grid,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import TopicItem from 'containers/topics/TopicItem'

const TopicList = ({ ...props }) => {
	const history = useHistory()
	const { isLoading, isLoaded, topics, findTopics } =
		useTopics({})
	const classes = useStyles()

	const { showAlertSuccess, showAlertError } =
		useAlerts()

	const handleClick = (topic) => {
		history.push(`/topics/${topic.id}`)
	}

	useEffect(() => {
		findTopics()
	}, [])

	return (
		<Container maxWidth="lg">
			<center className={classes.subheader}>
				<Typography variant="h3" color="primary">
					Editorial Videos
				</Typography>
			</center>
			{isLoaded && (
				<Grid container spacing={1}>
					{topics.map((topic, i) => (
						<Grid item xs={12} sm={6} md={4} key={i}>
							<TopicItem
								topic={topic}
								handleClick={handleClick}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</Container>
	)
}

export default TopicList

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	subheader: {
		margin: theme.spacing(4, 1, 4, 1),
	},
}))
