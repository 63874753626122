import React, {
	useState,
	useEffect,
	useContext,
} from 'react'
import { useLessons } from 'hooks'
import { AppContext } from 'context'
import {
	Button,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import ProgressBar from 'containers/skills/ProgressBar'
import LevelUpModal from 'containers/lessons/LevelUpModal'
import { PhotoCamera } from '@material-ui/icons'
import { StickyButton } from 'components'

const MODAL_DELAY = 1000

const LessonProgress = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		isLoaded,
		lesson,
		findLesson,
		findLevelProgress,
	} = useLessons({
		id: id,
	})

	const { currentUser } = useContext(AppContext)

	const classes = useStyles()
	const [progress, setProgress] = useState()
	const [levelUps, setLevelUps] = useState([]) //Array of skill values that level up
	const [currentIndex, setCurrentIndex] = useState(0)
	const [showModal, setShowModal] = useState(false)

	const handleClick = () => {
		history.push(`/courses/${lesson.course_id}`)
	}

	const handleLevelUps = async () => {
		const resp = await findLevelProgress(id)
		setProgress(resp.progress)
		setLevelUps(resp.level_ups)
		if (resp.level_ups?.length > 0) {
			setTimeout(
				() => setShowModal(true),
				MODAL_DELAY
			)
		}
	}

	const handleClose = (value) => {
		setShowModal(false)
		let nextIndex = currentIndex + 1
		if (nextIndex < levelUps.length) {
			setCurrentIndex(nextIndex)
			setTimeout(
				() => setShowModal(true),
				MODAL_DELAY
			)
		}
	}

	const handleUploadClick = () => {
		history.push(
			`/courses/${lesson?.course_id}/videos/upload`
		)
	}

	useEffect(() => {
		if (currentUser) {
			handleLevelUps()
		}
	}, [currentUser])

	useEffect(() => {
		if (id) {
			setCurrentIndex(0)
			setShowModal(false)
			findLesson(id)
		}
	}, [id])

	return (
		<div className={classes.root}>
			<center>
				<Typography
					variant="h2"
					className={classes.title}
				>
					SKILLS PROGRESS
				</Typography>
			</center>
			<Container maxWidth="sm">
				<div>
					{progress &&
						progress?.map((skill, index) => {
							const {
								value,
								label,
								current_level,
								visible_points,
								new_points,
							} = skill

							console.log(skill)

							return (
								<ProgressBar
									key={index}
									label={label}
									icon={value}
									secondaryLabel={`Level ${current_level}`}
									points={visible_points}
									newPoints={new_points}
									maxPoints={10}
								/>
							)
						})}
				</div>
				<LevelUpModal
					open={showModal}
					handleClose={handleClose}
					level={levelUps[currentIndex]}
				/>
			</Container>
			<StickyButton>
				<div className={classes.buttonContainer}>
					<center>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleUploadClick}
							className={classes.button}
							startIcon={<PhotoCamera />}
						>
							UPLOAD YOUR HIGHLIGHTS
						</Button>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleClick}
							className={classes.button}
						>
							RETURN TO OVERVIEW
						</Button>
					</center>
				</div>
			</StickyButton>
		</div>
	)
}

export default LessonProgress

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 100,
	},
	title: {
		fontSize: 58,
		marginBottom: theme.spacing(4),
	},
	button: {
		marginBottom: theme.spacing(1),
		maxWidth: 320,
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: theme.spacing(1),
	},
}))
