import React from 'react'
import { usePaywall } from 'hooks'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { Lock, PlayArrow } from '@material-ui/icons'
import { ResponsiveImage } from 'components'
import { resize } from 'lib/cloudinary'
import {
	THUMBNAIL_HEIGHT,
	THUMBNAIL_WIDTH_X3,
} from 'lib/constants'

const EditorialItem = ({
	editorial,
	handleClick,
	width = THUMBNAIL_WIDTH_X3,
	isLocked,
	...props
}) => {
	const classes = useStyles()

	const { isPaid } = usePaywall()

	const imageUrl = resize(editorial.thumbnail_url, {
		width: width,
		height: THUMBNAIL_HEIGHT,
	})

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => handleClick(editorial)}
			>
				<div className={classes.image}>
					<div className={classes.label}>
						<Typography variant="caption">
							{editorial.video_length}
						</Typography>
					</div>
					<div className={classes.iconContainer}>
						{editorial?.premium && !isPaid ? (
							<Button
								variant="contained"
								startIcon={<Lock />}
							>
								Upgrade for Access
							</Button>
						) : (
							<PlayArrow className={classes.icon} />
						)}
					</div>
					<ResponsiveImage
						src={imageUrl}
						borderRadius
						minHeight={240}
						width={width}
						gradient
						opacity={isLocked ? 0.6 : 1.0}
					/>
				</div>
			</Button>
			<Typography
				variant="body2"
				color="secondary"
				className={classes.caption}
			>
				{editorial?.topic?.title}
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={classes.title}
			>
				{editorial.title}
			</Typography>
		</div>
	)
}

export default EditorialItem

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 140,
		minWidth: 140,
	},
	caption: {
		textTransform: 'uppercase',
	},
	button: {
		padding: 0,
	},
	image: {
		width: '100%',
		overflow: 'hidden',
	},
	label: {
		position: 'absolute',
		zIndex: 999,
		padding: theme.spacing(0, 1),
		borderRadius: 20,
		margin: theme.spacing(2),
		backgroundColor: '#CDCECF',
		color: theme.palette.background.main,
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		zIndex: 999,
		width: '100%',
		height: '100%',
	},
	icon: {
		height: 60,
		width: 60,
		color: theme.palette.common.white,
	},
}))
