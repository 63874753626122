import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { AppContext } from 'context'
import { useHistory } from 'react-router'
import {
	useAuth,
	usePaywall,
	useAlerts,
	useCourses,
	useUsers,
} from 'hooks'
import {
	Box,
	Paper,
	Button,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { StickyButton, CoverImage } from 'components'
import CourseMeta from 'containers/courses/CourseMeta'
import LessonList from 'containers/lessons/LessonList'
import CourseLeaveModal from 'containers/courses/CourseLeaveModal'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'

const CourseShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params
	const [open, setOpen] = useState(false)

	const classes = useStyles()
	const { fetchMe } = useAuth()
	const { showAlertSuccess } = useAlerts()

	const { currentUser } = useContext(AppContext)
	const { unenrollCourse } = useUsers()

	const { course, findCourse } = useCourses({
		id: id,
	})

	const {
		isPaid,
		openPaywall,
		showPaywall,
		hidePaywall,
	} = usePaywall()

	const handleLessonClick = (lesson) => {
		if (lesson.premium && !isPaid) {
			showPaywall()
		} else {
			history.push(
				`/courses/${lesson.course_id}/lessons/${lesson.id}`
			)
		}
	}

	const handleUnenrollCourse = async () => {
		await unenrollCourse(course?.id)
		showAlertSuccess('You have left this course')
		history.push(`/courses`)
	}

	useEffect(() => {
		if (id) {
			fetchMe()
			findCourse(id)
		}
	}, [id])

	useEffect(() => {
		if (
			currentUser?.id &&
			currentUser?.course?.id != course?.id
		) {
			history.push(`/courses/${course.id}/enroll`)
		}
	}, [currentUser, course])

	return (
		<>
			{course?.image2_url && (
				<CoverImage
					height={520}
					desktopImageUrl={course.web_image2_url}
					mobileImageUrl={course.image2_url}
					gravity="center"
				/>
			)}
			<Container maxWidth={'md'}>
				<div>
					<Box p={2}>
						<center>
							<Typography
								variant="subtitle1"
								color="secondary"
								className={classes.subtitle}
							>
								{course.subtitle}
							</Typography>
							<Typography
								variant="h2"
								color="textPrimary"
								className={classes.title}
							>
								{course.title}
							</Typography>
							{false && (
								<CourseMeta course={course} />
							)}
						</center>
					</Box>
					<LessonList
						lessons={course?.lessons}
						handleClick={handleLessonClick}
					/>
				</div>
				<CourseLeaveModal
					open={open}
					handleClose={() => setOpen(false)}
					handleClick={handleUnenrollCourse}
				/>
			</Container>
			<StickyButton>
				<Button
					fullWidth
					variant="contained"
					color="secondary"
					onClick={() => setOpen(true)}
				>
					Leave Course
				</Button>
			</StickyButton>
			<PremiumSignupModal
				open={openPaywall}
				handleClose={hidePaywall}
			/>
		</>
	)
}

export default CourseShow

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {
		fontSize: 78,
		lineHeight: 0.8,
	},
	subtitle: {
		textTransform: 'uppercase',
	},
}))
