import React from 'react'
import {
	CardActionArea,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { ResponsiveImage } from 'components'
import { resize } from 'lib/cloudinary'
import {
	THUMBNAIL_HEIGHT,
	THUMBNAIL_WIDTH_X3,
} from 'lib/constants'

const ArticleItem = ({
	article,
	handleClick,
	width = THUMBNAIL_WIDTH_X3,
	height = THUMBNAIL_HEIGHT,
	isLocked,
	...props
}) => {
	const classes = useStyles({
		width: width,
		height: height,
	})

	const imageUrl = resize(article.image_url, {
		width: width,
		height: height,
	})

	return (
		<div className={classes.root}>
			<CardActionArea
				onClick={() => handleClick(article)}
			>
				<ResponsiveImage
					width={width}
					src={imageUrl}
					borderRadius
					gradient
					opacity={isLocked ? 0.6 : 1.0}
				/>
			</CardActionArea>
			<Typography
				variant="body2"
				color="secondary"
				className={classes.caption}
			>
				{article.category}
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={classes.title}
			>
				{article.title}
			</Typography>
		</div>
	)
}

export default ArticleItem

const useStyles = makeStyles((theme) => ({
	root: {
		height: 340,
		maxWidth: (props) => props.width,
	},
	button: {
		padding: 0,
		borderRadius: 0,
	},
}))
